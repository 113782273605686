import axios from "axios";
import { createContext, useEffect, useState } from "react";import React from "react";
export const AuthContext = createContext();
export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user') || null));
    const [err, setError] = useState("");
    // const url = "https://walifride.com";
    const url = "https://www.waliifride.com";
    // const url ="http://localhost:3001"
    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(currentUser));
    }, [currentUser]);
    useEffect(() => {
          axios.post(url + '/auth/check/users');
         // accountSet(response.data);       active
         
    });
   
    const login = async (inputs) => {
        try {
            const res = await axios.post(url + '/auth/login', inputs);
            if (!res.data.error) {
                const { accessToken, ...userData } = res.data;
                setCurrentUser({ ...userData, accessToken })
                window.location.href = '/home';
                return null;
            } else {
                return res.data.error;
            }
        } catch (error) {
            console.error(error);
            return error;
        }
    };

    const logout = async () => {
        await axios.post(url + '/auth/logout',{username:currentUser.username}).then((res) => {
            setCurrentUser(null);
            window.location.href = '/portal';
        });
    }

    const accaunts = async (inputs) => {
        const res = await axios.post(url + "/chargerequests/reg", inputs);
    }
    const createacc = async (inputs) => {
        const res = await axios.post(url + "/accounts/reg", inputs);
    }
    return (
        <AuthContext.Provider value={{ currentUser, err, url, login, logout, accaunts, createacc }}>{children}</AuthContext.Provider>
    )
};