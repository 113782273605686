import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import LocalTaxiOutLinedIcon from "@mui/icons-material/LocalTaxiOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
// import Header from "../../components/Header";
import Tab from '@mui/material/Tab';
import { ResponsiveLine } from "@nivo/line";
import Mony from'@mui/icons-material/SaveAsRounded';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import EmailIcon from "@mui/icons-material/Email";
// import DatePicker from '@mui/material/DatePicker';
import List from '@mui/material/List';
import StatBox from "../../components/StatBox";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import {Link, useNavigate,useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Modal from "@mui/material/Modal"; 
import { useFormik ,Formik} from 'formik';
import { Input ,Snackbar,Alert,Stack,FormLabel} from '@mui/material';
import { AuthContext } from "../../context/AuthContext";
import FileSaver from 'file-saver';
import MyDatePicker from '../../components/MyDatePicker';
import * as XLSX from 'xlsx';
import { LineChart, Line,CartesianGrid,BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      style={{width: '100%'}}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Report = () => {
  const { currentUser, url,logout } = useContext(AuthContext);
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
const [driver, setDriver] = useState([]);
const theme = useTheme();
const colors = tokens(theme.palette.mode);
// const url="https://walifride.com";
// const canvasRef = useRef(null); 
const navigate = useNavigate()

const [transactionCounts, setTransactionCounts] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
const [totalItems, setTotalItems] = useState(0);
const [value, setValue] = React.useState(0);


const handleChange = (event, newValue) => {
  setValue(newValue);
};

const handleChangeIndex = (index) => {
  setValue(index);
};
// let x=1;
useEffect(() => {
  handlemonthly();
}, [currentPage, itemsPerPage]);
// useEffect(() => {
  const handlemonthly = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + "/api/generalreport/report/general", { username: currentUser.username }, config);
      setTransactionCounts(response.data);
      // setTransactionCounts(data.rows); // Set data for the current page
      setTotalItems(response.data.count);
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
  }


  useEffect(() => {
    handletransaction();
  }, [currentPage, itemsPerPage]);
  // useEffect(() => {
    const handletransaction = async () => {
      // x=2;
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + "/api/generalreport/report/general/daily", { username: currentUser.username }, config);
        setTransactionCounts(response.data);
        // setTransactionCounts(data.rows); // Set data for the current page
        setTotalItems(response.data.count);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
    }


    const [userrep, setUserRep] = useState([]);
    const [agentCount, setAgentCount] = useState([]);

    const [reve, setRevenue] = useState([]);


    useEffect(() => {
      handletransactionuser();
    }, [currentPage, itemsPerPage]);
    // useEffect(() => {
      const handletransactionuser = async () => {
        // x=2;
        try {
          const config = {
              headers: {
                  'Authorization': `Bearer ${currentUser.accessToken}`
              }
          };
          const response = await axios.post(url + "/api/generalreport/report/employee/daily", { username: currentUser.username }, config);
          setUserRep(response.data.transactionCounts);
          setRevenue(response.data.revenue) 
      
        }catch (e) {
          if (e.toJSON().status === 401) {
              console.log('No valid token or session expired');
              logout();
          } else {
              console.error(e);
          }
      }
      }


const chartData = transactionCounts.map((count) => ({
  date: count.transactionDate, // Assuming transactionDate is formatted for display
  count: count.transactionCount,
}));

const userchartData = userrep.map((count) => ({
  date: count.report, // Assuming transactionDate is formatted for display
  count: count.transactionCount,
}));

const revenueLineChart = reve.map(item => ({
  name: item.createdAt,
  value: item.amaunt
}));

const handleExport = () => {
  const worksheet = XLSX.utils.json_to_sheet(reve);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(excelBlob, 'revenue.xlsx');
};

const [statusOrder, setOrderStatus]= useState('');
const [values, setValues] = useState({
  orderType: "",
  username:currentUser.username,
  end:"" ,
  start:""
})

function generateReport(e){
  e.preventDefault()
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };

// alert(values.orderType)
// alert(values.username)

// alert(values.start)
// alert(values.end)


  axios.post(url+`/api/generalreport/generateReport/orders`, values,config)
  .then((res)=>{
    setOrderStatus(res.data.ordersstatus);
    setAgentCount(res.data.agentCount);

    handleExportOrders()
  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }

};
}

const handleExportOrders = () => {
  const worksheet = XLSX.utils.json_to_sheet(statusOrder);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(excelBlob, 'orders.xlsx');
};


//Line Graph


const [data, setData] = useState([]);

// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const response = await axios.get('your-api-endpoint'); // replace with your actual API endpoint
//       setData(response.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   fetchData();
// }, []);

// const chartDataline = data.map((item) => {
//   return {
//     username: item.username,
//     transactionCount: item.transactionCount,
//   };
// });

//End Line Graph

  return (
    <Box m="20px" style={{ height: "85%" }}>
      <Box
        sx={{ width: "100%", height: "100%" }}
        p="10px"
        backgroundColor={colors.grey[400]}
      >
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            backgroundColor="dark"
            textColor="inherit"
            variant="fullWidth"
            // aria-label="full"
            display="flex"
          >
            <Tab label="Transactions " {...a11yProps(0)} />
            {currentUser.accesslevel == "admin" && (
              <Tab label="Orders" {...a11yProps(1)} />
            )}
            {currentUser.accesslevel == "admin" && (
              <Tab label="Employee" {...a11yProps(2)} />
            )}
            {currentUser.accesslevel == "admin" && (
              <Tab label="Company" {...a11yProps(3)} />
            )}
            {currentUser.accesslevel == "admin" && (
              <Tab label="Summery" {...a11yProps(4)} />
            )}
            {currentUser.accesslevel == "admin" && (
              <Tab label="UserLog" {...a11yProps(5)} />
            )}
            {/* <Tab label="Agents" {...a11yProps(6)} /> */}
          </Tabs>
        </AppBar>

        {/* <Typography sx={{ m: 1 }}> */}
        {/* <Button type="submit" color="error" variant='contained'sx={{ m: 1 }}  >Profile</Button> */}
        {/* <Button type="submit" color="info" variant='contained'sx={{ m: 1 }}onClick={handleOpen2}>Confirm Vehicle</Button></Typography> */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          {/* first */}

          <form className="formContainer" encType="multipart/form-data">
            <Button
              type="submit"
              color="secondary"
              sx={{ m: 1 }}
              variant="contained"
              onClick={handletransaction}
            >
              Generate Daily Report
            </Button>
            <Button
              type="submit"
              color="secondary"
              sx={{ m: 1 }}
              variant="contained"
              onClick={handlemonthly}
            >
              Generate Monthly Report
            </Button>
          </form>

          <Box
            gridColumn="span 8"
            backgroundColor={colors.grey[400]}
            display="flex"
            alignItems="center"
            // justifyContent="center"
            name="dis"
            hidden
          >
            <Box
              gridColumn="span 5"
              backgroundColor={colors.grey[400]}
              display="flex"
              overflow="auto"

              // alignItems="center"
              // justifyContent="center"
            >
              {/* {x===1? */}
              <div style={{ height: 350 }}>
                <table>
                  <tr>
                    <td>Items</td>
                    <td> Count</td>
                  </tr>
                  <tbody>
                    {transactionCounts.map((count) => (
                      <tr key={count.transactionMonth}>
                        <td>{count.transactionMonth}</td>
                        <td>{count.transactionCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Pagination controls */}
              </div>
              {/* :null} */}
            </Box>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.grey[400]}
              display="flex"
              alignItems="center"
              // justifyContent="center"
            >
              <div style={{ width: "80%", height: 350 }}>
                <ResponsiveContainer>
                  <BarChart data={chartData}>
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#2884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <Typography alignItems="center"> Transactions Graph</Typography>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
        <Box
      gridColumn="span 8"
      backgroundColor={colors.grey[400]}
      display="flex"
      alignItems="center"
      hidden
      name="dis"
    >
      
        <form onSubmit={generateReport}>
          <FormControl sx={{ m: 3, width: '100%' }}>
            <Typography sx={{ m: 3 }}>Booking Summary Report</Typography>

            <FormLabel sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Start Date</FormLabel>
            <MyDatePicker
              name="start"
              value={values.start}
              onChange={(date) => setValues({ ...values, start: date })}
              required
              sx={{ m: 3, width: '100%' }}
            />

            <FormLabel sx={{ fontSize: '1rem', fontWeight: 'bold' }}>End Date</FormLabel>
            <MyDatePicker
              value={values.end}
              name="end"
              onChange={(date) => setValues({ ...values, end: date })}
              required
              sx={{ m: 3, width: '100%' }}
            />

            <FormLabel sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Order Status</FormLabel>
            <TextField 
              name="orderType" 
              onChange={(e) =>
                setValues({ ...values, orderType: e.target.value })
              }
              required
              select
              sx={{ m: 1, width: '70%' }}
          
            >
              <MenuItem value="success">Success</MenuItem>
              <MenuItem value="online">Online</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="scheduled">Scheduled</MenuItem>
            </TextField>
            <Button
  sx={{
    m: 2,
    width: '100%',
    backgroundColor: '#4CA9EE',
    '&:hover': {
      backgroundColor: '#3a92d1', // Optional: darker shade for hover effect
    },
  }}
  type="submit"
>
  Generate
</Button>
          </FormControl>
        </form>
        <table>
                  <thead backgroundColor={colors.blueAccent[400]}>
                    <tr>
                      <td>Total Bookings</td>
                      <td> Completed Bookings</td>
                      <td> Cancelled Bookings</td>
                      <td> Pending Bookings</td>
                    </tr>
                  </thead>
                  <tbody>
                    {agentCount.map((count) => (
                      <tr key={count.agentId}>
                        <td>{count.agentId}</td>
                        <td>{count.count}</td>
                        <td>{count.count}</td>
                        <td>{count.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
    </Box>
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
          <Button
            type="submit"
            color="secondary"
            sx={{ m: 1 }}
            variant="contained"
            onClick={handletransactionuser}
          >
            Generate Daily Report
          </Button>
          {/* <Button type="submit" color="secondary" sx={{ m: 1 }}variant='contained' onClick={handlemonthly}>Generate Monthly Report</Button> */}

          <Box
            gridColumn="span 8"
            backgroundColor={colors.grey[400]}
            display="flex"
            alignItems="center"
            // justifyContent="center"
            name="dis"
            hidden
          >
            <Box
              gridColumn="span 4"
              backgroundColor={colors.grey[400]}
              display="flex"
              alignItems="center"
              overflow="auto"
              justifyContent="center"
            >
              {/* {x===1? */}
              <div style={{ width: "110%", height: 400 }}>
                <table>
                  <thead backgroundColor={colors.blueAccent[400]}>
                    <tr>
                      <td>Item</td>
                      <td> Count</td>
                    </tr>
                  </thead>
                  <tbody>
                    {userrep.map((count) => (
                      <tr key={count.username}>
                        <td>{count.username}</td>
                        <td>{count.transactionCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Pagination controls */}
              </div>
              {/* :null} */}
            </Box>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.grey[400]}
              display="flex"
              alignItems="center"
              // justifyContent="center"
            >
              <div style={{ width: "80%", height: 300 }}>
                <ResponsiveContainer>
                  <BarChart data={userchartData}>
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#2884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <Typography alignItems="center"> Transactions Graph</Typography>
            </Box>
          </Box>
          {/* <LineChart
      width={800}
      height={400}
      data={chartData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="username" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="transactionCount" stroke="#8884d8" activeDot={{ r: 8 }} />
    </LineChart> */}
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Box m="20px">
            {/* GRID & CHARTS */}
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="140px"
              gap="20px"
            >
              {/* ROW 2 */}

              <Box
                gridColumn="span 8"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gridRow="span 3"
              >
                <Stack direction="row">
                  <Typography>
                    <Button
                      type="submit"
                      color="secondary"
                      sx={{ m: 1 }}
                      variant="contained"
                      onClick={handletransaction}
                    >
                      daily revenue
                    </Button>
                    <Button
                      type="submit"
                      color="secondary"
                      sx={{ m: 1 }}
                      variant="contained"
                      onClick={handleExport}
                    >
                      Export Revenue
                    </Button>
                  </Typography>
                </Stack>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  backgroundColor={colors.redAccent[400]}
                >
                  <LineChart
                    width={600}
                    height={300}
                    data={revenueLineChart}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    style={{ backgroundColor: "#f5f5f5" }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#8884d8"
                      activeDot={{ r: 10 }}
                    />
                  </LineChart>
                </LocalizationProvider>
              </Box>
              <Box
                gridColumn="span 4"
                gridRow="span 3"
                backgroundColor={colors.primary[400]}
                overflow="auto"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  colors={colors.grey[100]}
                  p="15px"
                >
                  <Typography
                    color={colors.grey[100]}
                    variant="h5"
                    fontWeight="600"
                  >
                    Over All Employee Transactions
                  </Typography>
                </Box>

                {/* {reve.map(item => ( */}

                {reve.map((ord, i) => (
                  <Box
                    key={`${ord.createdAt}-${i}`}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    p="15px"
                  >
                    <Box>
                      <Typography
                        color={colors.greenAccent[500]}
                        variant="h5"
                        fontWeight="600"
                      >
                        {ord.createdAt} --
                      </Typography>
                    </Box>
                    <Box color={colors.grey[100]}>{ord.amaunt}</Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );

};

export default Report;
