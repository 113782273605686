import React from "react";
import Layout from "./../components/Layout/Layout";
import { Link } from "react-router-dom";
import waliifTaxi from "../images/waliifTaxi.jpg";
import {Box,Button} from "@mui/material";
import "../styles/HomeStyles.css";
const Home = () => {
  return (
    <Layout>
      <div className="home" style={{ backgroundImage: `url(${waliifTaxi})`,  width:'100%', 
    height:'530px'}}>
        <div className="headerContainer" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '300px'}}>         
          <Box>
           <h1> Waliif Ride  </h1>     
          {/* <p>Best Taxi In Ethiopia</p> */}
          
            <Button style={{marginLeft:'20px', width:250}}>Download App Now</Button>
          
          </Box>

        </div>
           
      </div>
    </Layout>
  );
};

export default Home;
