import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import  { useEffect, useState , useContext} from "react";
import axios from 'axios';
import { blue } from "@mui/material/colors";
import Modal from "@mui/material/Modal"; 
import { AuthContext } from '../../context/AuthContext';
const style = { 
	position: "absolute", 
	top: "50%", 
	left: "50%", 
  bgcolor: blue,
	transform: "translate(-50%, -50%)", 
	width: 900, 
	bgcolor: "background.paper", 
	border: "2px solid #1111", 
	boxShadow: 24, 
	p: 4, 
}; 

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
 
function RiderPage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  let { id } = useParams();
  const { currentUser, url,logout} = useContext(AuthContext);
 
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false); 
	const handleOpen = () => setOpen(true); 
	const handleClose = () => setOpen(false); 
  
  
  const [fetch, setDsetFetch] = useState([]); 

  const [ordelist, seorderlist] = useState([]);
  useEffect(() => {
   axios.post(url+`/api/riders/orders/${id}`, {username:currentUser.username}).then((response) => {
     seorderlist(response.data);
      debugger;  

    }).catch(e => {
     console.log('error:', e);
   });
 });
 
    

  const [updatevalue, setValueUpdate] = useState({
    firstname:'',
    middlename:"",
    lastname:"",
    phonenumber:"",
    email:"",
    otp:"",
    export:"",
    status:"",
    
  });
  //  const url="http://128.140.76.56:3001";
  //  const url="http://localhost:3001";
  //  const url = "https://128.140.76.56:3443";
  // const url="https://walifride.com";
   //const url="http://116.203.134.102:3001";
   const updateRider = (e) => {
    e.preventDefault()
    setOpen(false);
    axios.put(url+`/api/riders/${id}`, updatevalue)
    .then((res)=>{
     // navigate("/riders");
    })
    .catch((err)=>console.log(err))
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [rider, setRider] = useState([]);
  useEffect(() => {
    axios.post(url+`/api/riders/${id}`,{username:currentUser.username}).then((response) => {
      setRider(response.data);
      console.log(rider);
      debugger;  

    }).catch(e => {
      console.log('error:', e);
    });
  });

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  function handlefetch(){
	

  }


  const [historyorder, sethistoryOrder] = useState([]);


  useEffect(() => {
    const fetchorderhistory = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + `/api/drivers/ridertriphistory/${id}`, { username: currentUser.username }, config);
        sethistoryOrder(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchorderhistory();
}, [currentUser.username, currentUser.accessToken]);


  const orderhistory = [
    {
      field: "drivername",
      headerName: "drivername",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "driverphone",
      headerName: "platenumber",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "platenumber",
      headerName: "platenumber",
      flex: 1,
    },
    
    {
      field: "requesttime",
      headerName: "requesttime",
      flex: 1,
    },
    {
      field: "distance",
      headerName: "distance",
      flex: 1,
    },
    {
      field: "duration",
      headerName: "duration",
     flex:1,
    
    },    
    {
      field: "startdate",
      headerName: "startdate",
     flex:1,
    
    },  
    {
      field: "fare",
      headerName: "fare",
     flex:1,
    
    },  
    {
      field: "enddate",
      headerName: "enddate",
     flex:1,
    
    },  
    {
      field: "status",
      headerName: "status",
     flex:1,
    
    }, 
    {
      field: "Detail",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
                colors.blueAccent[600]
            }
            borderRadius="4px"
          >
            {/* <Button type="submit" onClick={ () => {navigate(`/riders/${id}`)} }>
              <AdminPanelSettingsOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              </Typography>
            </Button> */}
          </Box>
        );
      },
    },
  ];


  return (
<Box m="20px"
    style={{height: '85%'}}
  >
  <Box sx={{ bgcolor: 'background.paper', width: '100%', height: '100%' }} p="10px">
  <AppBar position="static">
    <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
		      backgroundColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		      display="flex"
        >
      <Tab label="Manage Rider" {...a11yProps(0)} />
      <Tab label="Trip History" {...a11yProps(1)} />
      {/* <Tab label="Feedback" {...a11yProps(2)} /> */}
    </Tabs>
  </AppBar>
      
<TabPanel value={value} index={0} dir={theme.direction}>
	<div>  
   <Box
          gridColumn="span 3"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <TextField
                fullWidth
                variant="filled"
                type="text"
                label="firstname"
                size="small"
                disabled="true"     
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="middlename"
                size="small"
                disabled="true"
              sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="lastname"
                size="small"
               sx={{ gridColumn: "span 1" }}
                 disabled="true"
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="email"
                disabled="true"
                size="small"
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="phonenumber"
                size="small"
                disabled="true"
                sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="otp"
                size="small"
                disabled="true"
                 sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="status"
                size="small"
                disabled="true"
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="expotoken"
                size="small"
                disabled="true"
                 sx={{ gridColumn: "span 1" }}
              />
              
    </Box>     
          <Box
          gridColumn="span 3"
          color="secondary"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <TextField
                fullWidth
                variant="filled"
                type="text"
                disabled="true"
                size="small"
                value={rider.firstname}
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                disabled="true"
                size="small"
                value={rider.middlename}
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                size="small"
                value={rider.lastname}
                 sx={{ gridColumn: "span 1" }}
                 disabled="true"
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                size="small"
                disabled="true"
                value={rider.email}
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                disabled="true"
                size="small"
                value={rider.phonenumber}
                 sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                disabled="true"
                size="small"
                value={rider.otp}
                 sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                disabled="true"
                size="small"
                value={rider.status}
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                disabled="true"
                size="small"
                value={rider.expotoken}
                 sx={{ gridColumn: "span 1" }}
              />
    </Box>    
    <Button aria-describedby={open} onClick={handleOpen} variant="contained"> 
					Edit
		</Button>
        <Modal 
				open={open} 
				onClose={handleClose} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			> 
    <Box sx={style} > 
				<Typography id="modal-modal-title"
						variant="h6" component="h2"> 
					</Typography> 
					<Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
              <TextField
                fullWidth
                variant="filled"
                type="text"
               label="First Name"
                onChange={(e)=> setValueUpdate({...updatevalue, firstname: e.target.value})}
                size="small"
                defaultValue={rider.firstname}
                name="firstname"
                sx={{ gridColumn: "span 1" }}
                ></TextField>
                <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Middle Name"
                onChange={(e)=> setValueUpdate({...updatevalue, middlename: e.target.value})}
                size="small"
                name="middlename"
                defaultValue={rider.middlename}
                sx={{ gridColumn: "span 1" }}
                ></TextField>
             <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                size="small"
                onChange={(e)=> setValueUpdate({...updatevalue, lastname: e.target.value})}
                name="lastname"
                 sx={{ gridColumn: "span 1" }}
                 defaultValue={rider.lastname}
              ></TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                defaultValue={rider.email}
                label="Email"
                size="small"
                onChange={(e)=> setValueUpdate({...updatevalue, email: e.target.value})}
                name="email"
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone number"
                defaultValue={rider.phonenumber}
                size="small"
                onChange={(e)=> setValueUpdate({...updatevalue, phonenumber: e.target.value})}
                name="phonenumber"
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="OTP"
                defaultValue={rider.otp}
                size="small"
                onChange={(e)=> setValueUpdate({...updatevalue, otp: e.target.value})}
                name="otp"
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Status"
                defaultValue={rider.status}
                size="small"
                onChange={(e)=> setValueUpdate({...updatevalue, status: e.target.value})}
                name="status"
                 sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Expotoken"
                defaultValue={rider.expotoken}
                size="small"
                onChange={(e)=> setValueUpdate({...updatevalue, expotoken: e.target.value})}
                name="expotoken"
                 sx={{ gridColumn: "span 1" }}
              />
              <Button type="submit" color="secondary" variant="contained" onClick={ updateRider }>
                Save Changes
              </Button>&nbsp;&nbsp;
              <Button color="error" onClick={handleClose} position="left">
                  Close
              </Button>
              </Typography> 
              </Box>
            </Modal>
          <div>  
        </div>  
      </div>  
  </TabPanel>
  <TabPanel value={value} index={1} dir={theme.direction}>
  <TabPanel value={value} index={1} dir={theme.direction}>


<Box m="20px">
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        // gap="20px"
      >
               {/* ROW 2 */}
        <Box
          gridColumn="span 10"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gridRow="span 3"
        >  
         
         
         <DataGrid rows={historyorder} columns={orderhistory} components={{ Toolbar: GridToolbar }} ></DataGrid>


        </Box>
        <Box
          gridColumn="span 2"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
Riders trip summery            </Typography>
          </Box>
         
        </Box>

        {/* ROW 3 */}
       
       {/*  */}
        {/* </Box>  */}
      </Box>
    </Box>


</TabPanel>

    
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          Active
        </TabPanel>

      {/* </SwipeableViews> */}
    </Box>
    </Box>
  );

};

export default RiderPage;
