import React, { useState, useRef, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  TextField,
  MenuItem,
  useTheme,
  Button,
  Box,
  Alert,
  Snackbar,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { tokens } from "../../theme";
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  DirectionsService,
  DirectionsRenderer,
  Autocomplete,
} from "@react-google-maps/api";
import axios from "axios";

const ManualOrder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { currentUser, url, logout } = useContext(AuthContext);
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [tar, setTar] = useState();
  const [mapid, setMapId] = useState();
  const [waitingPassengerLoc, setwaitingPassengerLoc] = useState();
  const [waitingDriverLoc, setwaitingDriverLoc] = useState();
  const [activOrderLoc, setactivOrderLoc] = useState();

  useEffect(() => {
    const gettariff = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        };
        const response = await axios.post(
          url + "/api/tariffs/gettariffvehic_class",
          { username: currentUser.username },
          config
        );
        setTar(response.data.tar);
        setMapId(response.data.mapkey);

      } catch (e) {
        if (e.toJSON().status === 401) {
          console.log("No valid token or session expired");
          logout();
        } else {
          console.error(e);
        }
      }
    };
    gettariff();
  }, [currentUser.username, currentUser.accessToken]);

  const [googleMapsApiLoaded, setGoogleMapsApiLoaded] = useState(false);
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [origin, setOrigin] = useState({ lat: null, lng: null });
  const [destination, setDestination] = useState({ lat: null, lng: null });
  const [distance, setDistance] = useState(null);
  const [fare, setFare] = useState(null);
  const [ridetype, setridetype] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const originInputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const [selectedVehicle, setSelectedVehicle] = useState(0);

  const onLoad = (map) => {
    setMap(map);
    setGoogleMapsApiLoaded(true);
  };

  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  const onPlacesChanged = () => {
    if (!googleMapsApiLoaded) {
      return;
    }
    const originPlace = originInputRef.current.getPlace();
    const destinationPlace = destinationInputRef.current.getPlace();
    if (originPlace && destinationPlace) {
      const originLatLng = originPlace.geometry.location.toJSON();
      const destinationLatLng = destinationPlace.geometry.location.toJSON();

      setOrigin(originLatLng);
      setDestination(destinationLatLng);

      //calculate distance with DistanceMatrix API
      const service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [originLatLng],
          destinations: [destinationLatLng],
          travelMode: "DRIVING",
        },
        (response, status) => {
          if (status === "OK") {
            const distance = response.rows[0].elements[0].distance.text;
            setDistance(distance);
            // Calculate fare based on distance
            const fare = calculateFare(
              response.rows[0].elements[0].distance.value,
              selectedVehicle
            );
            setFare(fare);
          } else {
            console.error("Error:", status);
            alert("Unable to get a distance.");
          }
        }
      );
      setDirections({
        origin: originLatLng,
        destination: destinationLatLng,
        travelMode: "DRIVING",
      });
    }
  };

  const calculateFare = (distanceValue) => {
    // Fare calculation logic (replace with WALIIF RIDE logic)
    // alert(mapid.googlemapkey);
    setridetype(selectedVehicle.id);
    const baseFare = parseFloat(selectedVehicle.startfare); // Base fare
    const farePerKm = parseFloat(selectedVehicle.farekm); // Fare per kilometer
    const distanceInKm = distanceValue / 1000; // Convert distance to kilometers
    const fare = baseFare + farePerKm * distanceInKm;
    return fare.toFixed(2); // Round fare to 2 decimal places
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    // Phone number validation regex
    const phoneRegex = /^\d{9}$/;

    if (!phoneRegex.test(value)) {
      setPhoneNumberError("Please enter a valid 9-digit phone number");
    } else {
      setPhoneNumberError("");
    }
  };

  const handleSubmit = async () => {
    try {
      const originInput = originInputRef.current.getPlace();
      const destinationInput = destinationInputRef.current.getPlace();

      if (!originInput || !destinationInput) {
        console.error("Origin or destination not selected");
        // alert("Origin or destination not selected.");
        return;
      }

      const originText = originInput.formatted_address;
      const destinationText = destinationInput.formatted_address;

      const formData = {
        riderid: "",
        ridername: "",
        riderlat: parseFloat(origin.lat),
        riderlng: parseFloat(origin.lng),
        ridetype: ridetype,
        startlocation: originText,
        endlocation: destinationText,
        startlat: parseFloat(origin.lat),
        startlng: parseFloat(origin.lng),
        endlat: parseFloat(destination.lat),
        endlng: parseFloat(destination.lng),
        riderphone: phoneNumber,
        distance: parseFloat(distance.replace('km',0)),
        //distance: parseFloat(distance),
        fare: fare,
        duration: "",
      };

      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      };
      // alert(formData.origin)
      await axios.post(
        url + "/api/orders/rider/waiting_remove",
        formData,
        config
      ).then((res) => {;
      setNotification({
        show: true,
        // position:top,
        type: 'info',
        message: "Request is seccussfully sent and Customer will get SMS trip info"
    });
    
      setIsRedirecting(true);
      const timer = setTimeout(() => {
        window.location.reload();
        }, 2000);
    
      return () => clearTimeout(timer);
      });

    } catch (error) {
      console.error("Error:", error);
      // Handle error here
      alert("no permission to do the activities");
    }
  };

  return (
    <Box m="20px">
      <LoadScript
        // googleMapsApiKey="AIzaSyATMRMaR7DmPq8kJiuCKoQALiXUtlF7FFA(Remover)"
        //  googleMapsApiKey="AIzaSyAI1laxMVfe2TviLNbXn5_GsLnAxeeyQl8(Remove)"
        googleMapsApiKey="AIzaSyAXlVaHCW6Ei26Koj2BzCYC24cJHjGc64g"
        // googleMapsApiKey={mapid.googlemapkey}
        libraries={["places"]}
      >

{notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}
        <GoogleMap
          id="map"
          mapContainerStyle={{
            height: "300px",
            width: "100%",
          }}
          zoom={10}
          center={{
            lat: 9.0192,
            lng: 38.7525,
          }}
          onLoad={onLoad}
        >
          {directions && (
            <DirectionsService
              options={{
                destination: directions.destination,
                origin: directions.origin,
                travelMode: "DRIVING",
              }}
              callback={(result) => {
                if (result !== null) {
                  setDirections(result);
                }
              }}
            />
          )}
          {directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
        <Box backgroundColor={colors.grey[400]}>
          <h1
            style={{
              fontSize: `20px`,
              fontWeight: `bold`,
              marginLeft: `10px`,
              padding: `10px`,
            }}
          >
            Create New Trip
          </h1>
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 10 }}
          >
            <div style={{ display: "flex", flexDirection: "row",marginLeft:`20px`,marginBottom:`20px` }}>
              <TextField
                type="text"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                ref={phoneInputRef}
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  // width: 200,
                  // height: 20,
                  marginRight:`20px`
                  
                }}
                pattern="[1-9]*"
                inputProps={{
                  maxLength: 9,
                  minWidth:9
                }}
              />
              <TextField
              label=" selct ride type"
                select
                name="vclass"
                sx={{
                  width: 200,
                  // height: 20,
                  
                }}
              >
                {tar ? (
                  tar.map((vehicle) => (
                    <MenuItem
                      id={vehicle.id}
                      value={vehicle.id}
                      onClick={() => setSelectedVehicle(vehicle)}
                    >
                      {vehicle.vehicle_class}
                      {vehicle.seatnumber}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No data available</MenuItem>
                )}
              </TextField>
            </div>

            {phoneNumberError && (<div style={{ color: "red", marginLeft:`20px` }}>{phoneNumberError}</div> )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row",marginBottom:`20px`,marginLeft:`20px` }}>
                <Autocomplete
                  onLoad={(originInput) =>
                    (originInputRef.current = originInput)
                  }
                  onPlaceChanged={onPlacesChanged}
                  //Change 'ET' to the target country code
                  options={{
                    componentRestrictions: { country: "ET" },
                    strictBounds: true,
                  }}
                >
                  <TextField
                    type="text"
                    placeholder="Enter origin"
                    name="originInput"
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      // width: 200,
                      // height: 20,
                      marginRight:`20px`
                    }}
                  />
                </Autocomplete>

                {/* {origin.lat !== null && origin.lng !== null && (
          <div>
            Origin Latitude: {origin.lat}, Longitude: {origin.lng}
          </div>
        )} */}

                <Autocomplete
                  onLoad={(destinationInput) =>
                    (destinationInputRef.current = destinationInput)
                  }
                  onPlaceChanged={onPlacesChanged}
                  //Change 'ET' to the target country code
                  options={{
                    componentRestrictions: { country: "ET" },
                    strictBounds: true,
                  }}
                >
                  <TextField
                    type="text"
                    placeholder="Enter destination"
                    name="destinationInput"
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      // width: 200,
                      // height: 20,
                    }}
                  />
                </Autocomplete>
              </div>
            </div>
            {/* {destination.lat !== null && destination.lng !== null && (
          <div>
            Destination Latitude: {destination.lat}, Longitude:{" "}
            {destination.lng}
          </div>
        )} */}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {distance && (
              <div style={{marginLeft:`20px`, padding: `0 12px` }}>
                Distance: <b>{distance}</b>
              </div>
            )}
            {fare && (
              <div style={{marginLeft:`20px`, padding: `0 12px` }}>
                Fare: <b>{fare} Birr</b>
              </div>
            )}
          </div>

          <Box>
            <Button
              onClick={handleSubmit}
              color="secondary"
              variant="contained"
              position="center"
              style={{
                width: 100,
                height: 30,
                // marginLeft:`20px`,
                // marginTop:`20px`,
                margin:`20px`

              }}
            >
              Book a Ride
            </Button>
          </Box>
        </Box>
      </LoadScript>
    </Box>
  );
};

export default ManualOrder;
