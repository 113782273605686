import { Box,Divider, FormControl,Snackbar,FormControlLabel ,Alert, Select,MenuItem,InputLabel,List,ListItem, ListItemText,Typography, useTheme,Button,TextField, Checkbox} from "@mui/material";
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import NewItem from "@mui/icons-material/PeopleAlt";
import Orders from "@mui/icons-material/TravelExploreSharp";
import Active from "@mui/icons-material/WorkHistoryOutlined";
import Vehicle from "@mui/icons-material/CarRental";
import DriverIcon from "@mui/icons-material/Add";


import { AuthContext } from "../../context/AuthContext";
import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "@mui/material/Modal"; 
// import Wallet from "../../images/Wallet.png"
import * as yup from 'yup';
import {Formik, Field, ErrorMessage, useFormik } from 'formik';
const style3 = { 
	position: "absolute", 
	top: "50%", 
	left: "60%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 800, 
  borderColor: "#d50000", 
	p: 4, 
}; 


const Driver = () => {
  const { currentUser,url ,logout,isSessionExpired} = useContext(AuthContext);
const theme = useTheme();
const colors = tokens(theme.palette.mode);
const [notification, setNotification] = useState(null);
const [isRedirecting, setIsRedirecting] = useState(false);
const [preview, setPreview] = useState('');
const navigate = useNavigate()
const isNonMobile = useMediaQuery("(min-width:600px)");
let controldisp=0

const formik = useFormik({
initialValues: {
firstname: "",
middlename: "",
lastname: "",
email: "",
phonenumber: "",
licenceId:"",
pin:"00",
otp:"00",
balance:"",
platenumber:"",
status:"",
expotoken:currentUser.username,
location:"",
photo: "",
username:currentUser.username,
transactionmethod:""
}, onSubmit: (values) => {
  // console.log(values);
  //alert(values.platenumber+"wewwewe");
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  //  alert(values[value]); 
  }
  const registerdriver6 = async () => {
    
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
  
 axios.post(url+"/api/drivers", formData,config).then((res) => {
    // setDriverReg(driverReg.concat(res.data));
    setOpen(false);
    if(res.data=="Driver registration completed, status is inactive"){
    setNotification({
      vertical: 'top', horizontal: 'right',
      show: true,
      type: 'success',
      message: "Driver registration completed, status is new" 
  });
}else if(res.data=="no"){
  setNotification({
    show: true,
    type: 'error',
    message: "No Vehicle assosiated to plate number" 
});
}else if(res.data=="exist"){
    setNotification({
      show: true,
      type: 'info',
      message: "The driver is already registered with this phonenumber" 
  });}
  else{
    setNotification({
      show: true,
      type: 'error',
      message: res.data 
  });

  }
    setIsRedirecting(true);
    const timer = setTimeout(() => {
        
       }, 3000);
  
    return () => clearTimeout(timer);
  });
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
};
registerdriver6();

},

});
 
	const [open, setOpen] = React.useState(false); 
  

  //manage register modal
	const handleOpen = () => setOpen(true);
  const handleClosereg = () => setOpen(false);
  

  //manage register modal


  const [driver, setDriver] = useState([]);
  const [asignedVehic, setAssigned] = useState([]);
  const [active, setActive] = useState([]);
  const [all, SetAllDriver] = useState([]);
  const [listActive, setlistActive] = useState([]);
  const [balanceA, setBalance] = useState([]);
  const [records, setrecords] = useState([]);
  const [orders, setOrders] = useState([]);


  

  

   useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/drivers/all', { username: currentUser.username }, config);
        setDriver(response.data.driver);
        setAssigned(response.data.ass);
        setActive(response.data.active);
        SetAllDriver(response.data.all);
        setlistActive(response.data.listActive)
        setBalance(response.data.balance)
        setrecords(response.data.records)
        setOrders(response.data.orders)
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

fetchDrivers();
}, [currentUser.username, currentUser.accessToken]);
function dispActiveDriver(){
  controldisp=1;
  
}
function dispAllDriver(){
  controldisp=0;
}

 
  const [platenumer, setPlate]=useState([]);
  useEffect(() => {
    const fetchplate = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/vehicles/plate', { username: currentUser.username }, config);
        setPlate(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchplate();
}, [currentUser.username, currentUser.accessToken]);

  //Form Validation
  const registerSchema = yup.object().shape({
    firstname: yup.string().required('First Name is required'),
    middlename: yup.string().required('Middle Name is required'),
    lastname: yup.string().required('Middle Name is required'),
    balance: yup.string().required('Middle Name is required'),
    licenceId: yup.string().required('Middle Name is required'),
    platenumber: yup.string().required('Middle Name is required'),
    transactionmethod: yup.string().required('Middle Name is required'),
    phonenumber: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(9, 'Must be exactly 9 digits')
      .max(9, 'Must be exactly 9 digits')
      .required('Phone Number is required'),
    });


    const [eror, setError] = useState([""]);

   



  //End Form validation
  const columns = [
    // { field: "id", headerName: "ID" },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "middlename",
      headerName: "Midde Name",
      // type: "number",
      headerAlign: "left",
      align: "left",
      flex: 2,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      flex: 2,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      flex: 2,
    },
    {
      field: "phonenumber",
      headerName: "Phone Number", filter:true,
      flex: 1,
      flex: 2,
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
    },
    {
      field: "licenceId",
      headerName: "LicenceId",
      flex: 1,
      
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },

    {
      field: "accessLevel",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            backgroundColor={
              colors.blueAccent[600]
          }          
          >
          {currentUser.accesslevel == 'admin' &&
        <Button type="submit"   onClick={ () => {navigate(`/driver/${id}`)} }
        sx={{
          fontSize: '8px',
          padding: '6px 10px',
        }}
        >
          <AdminPanelSettingsOutlinedIcon />
        </Button>
        }
          </Box>
        );
      },
    },
     ];

  return (
    <Box m="20px"       
    >
   
    <Box
      m="40px 0 0 0"
      height="75vh"
      backgroundColor={colors.grey[400]}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    > 
       <div >
        <Box display="flex" justifyContent="end" mt="20px" hidden>
        {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
                      
         <Button  onClick={dispAllDriver} variant="contained" color="secondary"> 
					Total Driver :: {all}<NewItem/>
				 </Button>&nbsp;
         <Button  onClick={dispActiveDriver} variant="contained" color="secondary"> 
         Active Driver:{active}<Active/>

				 </Button>&nbsp;
         <Button  variant="contained" color="secondary"> 
					Today Total Earn<Active/>
				 </Button>&nbsp;
         <Button  variant="contained" color="secondary"> 
         <img src="./assets/wallet.png" alt='logo' style={{marginLeft: 5}} width={80} height={80} /> {balanceA}
 
				 </Button>&nbsp;
         <Button variant="contained" color="secondary"> 
         Total Free Vehicle : {asignedVehic}<Vehicle/>
				 </Button>&nbsp;
         <Button  variant="contained" color="secondary"> 
         Total Vehicle Assigned {records}<Vehicle/>
				 </Button>&nbsp;
         <Button variant="contained" color="secondary"> 
         Total Orders:{orders} <Orders/>
				 </Button>&nbsp;
         <Button aria-describedby={open} onClick={handleOpen} variant="contained" color="secondary"> 
				<DriverIcon/>Register
				 </Button>&nbsp;
        </Box>

         </div> 

     


        
    
    

      <Modal id="register"
          // backgroundColor="seagreen "
          // sx={style1} 
            open={open} 
            onClose={handleClosereg} 
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          > 
			  	 <Box
          //gridColumn="span 6"
          backgroundColor={colors.grey[400]}
          display="flex"
          gridRow="span 3" 
          // marginLeft={20}
          marginRight={50}
          width={20}
          sx={style3}
         // backgroundColor="#2979ff"
          >
					<Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
            {/* <form onSubmit={handleSubmit}> */}
         <Formik
            initialValues={{firstname: "", middlename: "",lastname:"",phonenumber:"",licenceId:"",balance:"",transactionmethod:"", platenumber:""}}
            validationSchema={registerSchema}
            onSubmit={onsubmit}
           >
            <form className='formContainer' onSubmit={formik.handleSubmit} encType='multipart/form-data'>
                    <ListItem >
                      <ListItemText primary="First Name"      
                      sx={{
                 '& .MuiInputBase-root': {
                   height: 30, // Custom height
                 },
               }}
               InputProps={{
                 style: {
                   padding: '0 14px', // Custom padding
                 },
               }}    />
                      <TextField  
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.firstname}
                          pattern="[A-Za-z]"
                          name="firstname"
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 40, // Custom height
                              m:1
                            },
                          }}
                          InputProps={{
                            style: {
                              padding: '0 14px', // Custom padding
                            },
                          }}   
                        />
                      {/* <ErrorMessage component='a' name='firstname' style={{color: 'red'}} /> */}
                      <ListItemText primary="Midle Name" 
                           sx={{
                            '& .MuiInputBase-root': {
                              height: 30, // Custom height
                            },
                          }}
                          InputProps={{
                            style: {
                              padding: '0 14px', // Custom padding
                            },
                          }}   
                      />
                      <TextField
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.middlename}
                          required
                          name="middlename"
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 40, // Custom height
                            },
                          }}
                          InputProps={{
                            style: {
                              padding: '0 14px', // Custom padding
                            },
                          }}   
                          />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Last Name "
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 30, // Custom height
                            },
                          }}
                          InputProps={{
                            style: {
                              padding: '0 14px', // Custom padding
                            },
                          }} />
                      <TextField
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.lastname}
                          name="lastname"
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 40,
                              // Custom height
                            },
                          }}
                          InputProps={{
                            style: {
                              padding: '0 14px', // Custom padding
                            },
                          }}                             >
                      </TextField>
                          
                          <ListItemText primary="Email  " 
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 20, // Custom height
                              },
                            }}
                            InputProps={{
                              style: {
                                padding: '0 14px', // Custom padding
                              },
                            }} 
                          />
              
                          <TextField
                          type="email"
                          label=""
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          size="small"
                          name="email"
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 40, // Custom height
                            },
                          }}
                          InputProps={{
                            style: {
                              padding: '0 14px', // Custom padding
                            },
                          }}   
                          ></TextField>
                          </ListItem>
                        <ListItem>
                        <ListItemText primary="Phone number" sx={{m:1}}/>
                        <TextField
                          type="text"
                          pattern="[1-9]*"
                          onChange={formik.handleChange}
                          value={formik.values.phonenumber}
                          required
                          inputProps={{
                            maxLength: 9,
                            minWidth:9
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              padding: '0 14px', // Custom padding
                            },
                          }}
                          helperText="Please Enter exactly nine digits,exampt 0 "
                          name="phonenumber"
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 40, // Custom height
                            },
                          }}
                          />
                       
                        <ListItemText primary="Licence Number  "   sx={{ m:1 }}/>
                        <TextField
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.licenceId}
                          name="licenceId"
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 40, // Custom height
                            },
                          }}
                          InputProps={{
                            style: {
                              padding: '0 14px', // Custom padding
                            },
                          }}                             ></TextField>
                        </ListItem>
                        <ListItem>
                        <ListItemText primary="Location" sx={{m:1}}/>
                        <TextField
                          type="text"
                          // width={100}
                          onChange={formik.handleChange}
                          value={formik.values.location}
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 40, // Custom height
                            },
                          }}
                          InputProps={{
                            style: {
                              padding: '0 14px', // Custom padding
                            },
                          }}                            
                           name="location"
                          ></TextField>
                         
                        <ListItemText primary="Driver Photo "  sx={{m:1}}  />
                        <TextField    
                          type="file"
                          label=""
                          // required
                          name="image"
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 30, // Custom height
                            },
                          }}
                          InputProps={{
                            style: {
                              padding: '0 14px', // Custom padding
                            },
                          }}   
                          onChange={(e) => {
                            let reader = new FileReader();
                            reader.onload = () => {
                              if (reader.readyState === 2) {
                                formik.setFieldValue('image', e.target.files[0])
                                setPreview(reader.result)
                              }
                            }
                            reader.readAsDataURL(e.target.files[0]);
                          }}
                          ></TextField>
                         </ListItem>
                        {/* <ListItem> */}
                      
                        <ListItem>
                          {/* jjj */}
                          {/* kk */}
                        <ListItemText primary="Plate Number  "  size="small"  sx={{ height:50,width:300 }}/>
            
                          
                    <FormControl  sx={{
                 '& .MuiInputBase-root': {
                   height: 40, // Custom height
                 },
               }}
               InputProps={{
                 style: {
                   padding: '0 14px', // Custom padding
                 },
               }}    size="small"  id="dis5">
                   <Select name="platenumber"
                 onChange={formik.handleChange}
                   value={formik.values.platenumber}
                   size="small"
                   variant="filled"
                   required
                   sx={{
                    '& .MuiInputBase-root': {
                      height: 30, // Custom height
                    },
                  }}
                  InputProps={{
                    style: {
                      padding: '0 14px', // Custom padding
                    },
                  }}                      >
                      {platenumer.map(vehicle => (
                    <MenuItem id={vehicle.id} value={vehicle.id}>
                    {vehicle.platenumber}</MenuItem>))}
                    
                   </Select>
               </ FormControl>
                 </ListItem>
                        <Button type="submit" color="secondary" variant="contained" onClick={onsubmit}>
                          Create New Driver
                        </Button>&nbsp; 
                        <Button   onClick={handleClosereg} position="left" variant="contained"color="warning">
                          Close
                        </Button>
                       
              </form>
            </Formik>
					</Typography> 
        </Box> 
     	</Modal> 
     {controldisp==0?
        <DataGrid   rows={driver} columns={columns}  components={{ Toolbar: GridToolbar }} rowHeight={20}/>
       :
        <DataGrid   rows={listActive} columns={columns}  components={{ Toolbar: GridToolbar }} rowHeight={20}/>
}


    </Box>
  </Box>
);

};

export default Driver;
