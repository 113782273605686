import React, { useEffect, useState ,useContext} from "react";
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { LoadScript, GoogleMap, MarkerF, Marker } from '@react-google-maps/api';

import CustomMarker from '../../images/car50.png';

//import CustomMarker from "../assets/images/map-marker-svgrepo-com.svg";

const libraries = ['places']; // Optional for place name search

const PanoramicView = () => {
  const [locations, setLocations] = useState([]);
  const [pass, setPassenger] = useState([]);

  const { currentUser,url ,logout} = useContext(AuthContext);
 
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url+'/api/orders/all',{username:currentUser.username},config); // actual URL endpoint that provides your location data in JSON format
        console.log(response.data.orders);
        setLocations(response.data.online);
        setPassenger(response.data.waiting);

      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
    };
    fetchLocations();
  }, []);

  const mapContainerStyle = {
    width: '78vw',
    height: '45vw',
  };

  const center = {
    lat:9.0192, // Initial center location, Nekemte
    lng: 38.7525,
  };

  const zoom = 13;
    return (
    <div>
    <div  style={{textAlign:`center`, fontWeight:`bold`}}>
      <h1>PANORAMIC VIEW</h1>
    </div>
  <LoadScript
    googleMapsApiKey="AIzaSyAXlVaHCW6Ei26Koj2BzCYC24cJHjGc64g" // Your Google Maps API Key
    libraries={libraries}
  >
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
      
       center={center}
    >
     {locations.map((location) => (
          <MarkerF
            key={location.id} // Use unique identifier for each location
            position={{ lat:location.driverlat, lng:location.driverlng }}
            options={{ icon: CustomMarker }}
            title='WALIIF RIDE'
          />
        ))}
        
    </GoogleMap>
  </LoadScript>
        
  </div>
  
  );

};


export default PanoramicView;
