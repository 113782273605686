// import * as React from 'react';
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { tokens } from "../../theme";
// import SwipeableViews from 'react-swipeable-views';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, useNavigate } from "react-router-dom";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { DataGrid, GridToolbar, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
  Button,
  TextField,
  Checkbox,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const style2 = {
  position: "absolute",
  top: "50%",
  left: "55%",
  height: "100",
  transform: "translate(-50%, -50%)",
  width: 1000,
  borderColor: "#d50000",
  p: 4,
};
const stylePrint = {
  body: {
    "*": {
      visibility: "hidden",
    },
  },
  "#printable-content": {
    // visibility: 'visible',
    position: "absolute",
    top: "50%",
    left: "40%",
    height: "100",
    transform: "translate(-50%, -50%)",
    width: 1000,
    p: 4,
  },
};
function Orders() {
  const { currentUser, logout, url } = useContext(AuthContext);
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClosereg = () => setOpen(false);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [status, setApproval] = useState({
    username: currentUser.username,
    status: "",
  });
  function hadlefeedbackresponse(id) {
    axios
      .put(url + `/api/feedbacks/${id}`, status)
      .then((res) => {
        alert(res.data);
        // navigate('/agents');
      })
      .catch((err) => console.log(err));
  }
  const [ordelist, seorderlist] = useState([]);
  const [waiting, setWaiting] = useState([]);
  const [gwaiting, setGroupWaiting] = useState([]);
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        };
        const response = await axios.post(
          url + "/api/orders/all",
          { username: currentUser.username },
          config
        );
        seorderlist(response.data.orders);
        setWaiting(response.data.waiting);
        setGroupWaiting(response.data.gwaiting);
      } catch (e) {
        if (e.toJSON().status === 401) {
          console.log("No valid token or session expired");
          logout();
        } else {
          console.error(e);
        }
      }
    };

    fetchOrder();
  }, [currentUser.username, currentUser.accessToken]);

  const [feedbacks, setfeedbaclList] = useState([]);
  useEffect(() => {
    const fetchsetfeedbaclList = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        };
        const response = await axios.post(
          url + "/api/feedbacks/all",
          { username: currentUser.username },
          config
        );
        setfeedbaclList(response.data);
      } catch (e) {
        if (e.toJSON().status === 401) {
          console.log("No valid token or session expired");
          logout();
        } else {
          console.error(e);
        }
      }
    };

    fetchsetfeedbaclList();
  }, [currentUser.username, currentUser.accessToken]);

  function CancelWaitingOrder(wid) {
    const config = {
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`,
      },
    };
    axios
      .post(
        url + `/api/orders/waiting/cancel/${wid}`,
        { username: currentUser.username },
        config
      )
      .then((res) => {
        setOpen(false);
        setNotification({
          show: true,
          type: "error",
          message: res.data,
        });
        setIsRedirecting(true);
        const timer = setTimeout(() => {
          window.location.reload();
        }, 3000);
        return () => clearTimeout(timer);
      })
      .catch((err) => console.log(err));
  }
  const [openDetailTransaction, setopenDetailTransaction] =
    React.useState(false);
  const handleopenDetailTransaction = () => setopenDetailTransaction(true);
  const closeOpenTransaction = () => setopenDetailTransaction(false);

  const [idno, setId] = useState(0);
  const [drivername, setdrivername] = useState(0);
  const [driverid, setdriverid] = useState(0);
  const [driverphone, setdriverphone] = useState(0);
  const [platenumber, setplatenumber] = useState(0);
  const [driverlocation, setdriverlocation] = useState(0);
  const [driverlat, setdriverlat] = useState(0);

  const [driverlng, setdriverlng] = useState(0);
  const [riderid, setriderid] = useState(0);
  const [ridername, setridername] = useState(0);
  const [riderphone, setriderphone] = useState(0);
  const [endlocation, setendlocation] = useState(0);
  const [riderlat, setriderlat] = useState(0);
  const [riderlng, setriderlng] = useState(0);

  const [ridetype, setridetype] = useState(0);
  const [requesttime, setrequesttime] = useState(0);
  const [startlocation, setstartlocation] = useState(0);
  const [route, setroute] = useState(0);
  const [startlat, setstartlat] = useState(0);
  const [startlng, setstartlng] = useState(0);
  const [endlat, setendlat] = useState(0);

  const [endlng, setendlng] = useState(0);
  const [distance, setdistance] = useState(0);
  const [duration, setduration] = useState(0);
  const [fare, setfare] = useState(0);
  const [startdate, setstartdate] = useState(0);
  const [enddate, setenddate] = useState(0);
  const [statusO, setstatus] = useState(0);
  const [updatedAto, setupdatedAto] = useState(0);
  const [createdAt, setcreatedAt] = useState(0);
  const [reason, setreason] = useState(0);
  const [updatedAt, setupdatedAt] = useState(0);

  function handleOrderDetailDisp(
    id,
    driverid,
    drivername,
    driverphone,
    platenumber,
    driverlocation,
    driverlat,
    driverlng,
    riderid,
    ridername,
    riderphone,
    endlocation,
    riderlat,
    riderlng,
    ridetype,
    requesttime,
    startlocation,
    route,
    startlat,
    startlng,
    endlat,
    endlng,
    distance,
    duration,
    fare,
    startdate,
    enddate,
    status,
    reason,
    createdAt,
    updatedAt
  ) {
    let dateObject = new Date(createdAt);
    let formattedDate =
      dateObject.getFullYear() +
      "/" +
      (dateObject.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      dateObject.getDate().toString().padStart(2, "0");

    let dateObject2 = new Date(updatedAt);
    let formattedDate2 =
      dateObject2.getFullYear() +
      "/" +
      (dateObject2.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      dateObject2.getDate().toString().padStart(2, "0");

    setreason(reason);
    setdistance(distance);
    setduration(duration);
    setfare(fare);

    setendlng(endlng);
    setdistance(distance);
    setduration(duration);
    setfare(fare);
    setstartdate(startdate);
    setenddate(enddate);
    setstatus(status);

    setridetype(ridetype);
    setrequesttime(requesttime);
    setstartlocation(startlocation);
    setroute(route);
    setstartlat(startlat);
    setstartlng(startlng);
    setendlat(endlat);

    setdriverlng(driverlng);
    setriderid(riderid);
    setridername(ridername);
    setriderphone(riderphone);
    setendlocation(endlocation);
    setriderlat(riderlat);
    setriderlng(riderlng);

    setId(id);
    setdrivername(drivername);
    setdriverid(driverid);
    setdriverphone(driverphone);
    setplatenumber(platenumber);
    setdriverlocation(driverlocation);
    setdriverlat(driverlat);
    setcreatedAt(formattedDate);
    setupdatedAt(formattedDate2);

    setopenDetailTransaction(true);
  }

  function handleDiscard(orderID) {
    const config = {
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`,
      },
    };
    axios
      .post(
        url + `/api/orders/active/discard/${orderID}`,
        { username: currentUser.username },
        config
      )
      .then((res) => {
        setNotification({
          show: true,
          type: "error",
          message: res.data,
        });
        setIsRedirecting(true);
        const timer = setTimeout(() => {
          window.location.reload();
        }, 1000);
        return () => clearTimeout(timer);
      })
      .catch((err) => console.log(err));
  }


  
  const columns = [
    {
      field: "drivername",
      headerName: "drivername",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "driverphone",
      headerName: "driverphone",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "platenumber",
      headerName: "platenumber",
      flex: 1,
    },

    {
      field: "riderphone",
      headerName: "riderphone",
      filter: true,
      flex: 1,
    },
    {
      field: "requesttime",
      headerName: "requesttime",
      flex: 1,
    },
    {
      field: "distance",
      headerName: "distance",
      flex: 1,
    },
    {
      field: "duration",
      headerName: "duration",
      flex: 1,
    },
    {
      field: "startdate",
      headerName: "startdate",
      flex: 1,
    },
    {
      field: "fare",
      headerName: "fare",
      flex: 1,
    },
    {
      field: "enddate",
      headerName: "enddate",
      flex: 1,
    },
    {
      field: "status",
      headerName: "status",
      flex: 1,
    },
    {
      field: "Detail",
      headerName: "Detail3",
      flex: 1,
      renderCell: ({
        row: {
          id,
          driverid,
          drivername,
          driverphone,
          platenumber,
          driverlocation,
          driverlat,
          driverlng,
          riderid,
          ridername,
          riderphone,
          endlocation,
          riderlat,
          riderlng,
          ridetype,
          requesttime,
          startlocation,
          route,
          startlat,
          startlng,
          endlat,
          endlng,
          distance,
          duration,
          fare,
          startdate,
          enddate,
          status,
          reason,
          createdAt,
          updatedAt,
        },
      }) => {
        return (
          <Box backgroundColor={colors.blueAccent[600]}>
            {/* <Button onClick={()=>{handleopenDetail()}}> */}
            <Button
              onClick={() => {
                handleOrderDetailDisp(
                  `${id}`,
                  `${driverid}`,
                  `${drivername}`,
                  `${driverphone}`,
                  `${platenumber}`,
                  `${driverlocation}`,
                  `${driverlat}`,
                  `${driverlng}`,
                  `${riderid}`,
                  `${ridername}`,
                  `${riderphone}`,
                  `${endlocation}`,
                  `${riderlat}`,
                  `${riderlng}`,
                  `${ridetype}`,
                  `${requesttime}`,
                  `${startlocation}`,
                  `${route}`,
                  `${startlat}`,
                  `${startlng}`,
                  `${endlat}`,
                  `${endlng}`,
                  `${distance}`,
                  `${duration}`,
                  `${fare}`,
                  `${startdate}`,
                  `${enddate}`,
                  `${status}`,
                  `${reason}`,
                  `${createdAt}`,
                  `${updatedAt}`
                );
              }}
              sx={{
                fontSize: "8px",
                padding: "6px 10px",
              }}
            >
              <AdminPanelSettingsOutlinedIcon />
            </Button>
          </Box>
        );
      },
    },
  ];

  //GridColDef

  const waitingcolumns = [
    {
      field: "riderid",
      headerName: "riderid",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "ridername",
      headerName: "ridername",
      // type: "number",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "riderphone",
      headerName: " riderphone  ",
      flex: 1,
    },
    {
      field: "startlat",
      headerName: "startlat",
      flex: 1,
    },
    {
      field: "startlng",
      headerName: "startlng",
      flex: 1,
    },
    {
      field: "endlat",
      headerName: "endlat",
      flex: 1,
    },
    {
      field: "distance",
      headerName: "distance",
      flex: 1,
    },
    {
      field: "duration",
      headerName: "duration",
      flex: 1,
    },
    {
      field: "ridetype",
      headerName: "ridetype",
      flex: 1,
    },

    {
      field: "fare",
      headerName: "fare",
      flex: 1,
    },

    {
      field: "createdAt",
      headerName: "createdAt",
      filter: true,
      flex: 1,
    },
    {
      field: "Detail",
      headerName: "delete",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
          >
            {/* <Button onClick={()=>{handleopenDetail()}}> */}
            <Button
              onClick={() => {
                CancelWaitingOrder(`${id}`);
              }}
            >
              <AdminPanelSettingsOutlinedIcon />
              <Typography
                color={colors.grey[100]}
                sx={{ ml: "5px" }}
              ></Typography>
            </Button>
          </Box>
        );
      },
    },
  ];

  //GroupWaiting
  const groupWaitingColumn = [
    {
      field: "creatorid",
      headerName: "creatorname",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "creatorname",
      headerName: "creatorname",
      // type: "number",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "maxriders",
      headerName: " maxriders  ",
      flex: 1,
    },
    {
      field: "availableseats",
      headerName: "availableseats",
      flex: 1,
    },
    {
      field: "endlocation",
      headerName: "endlocation",
      flex: 1,
    },
    {
      field: "riderlat",
      headerName: "riderlat",
      flex: 1,
    },
    {
      field: "riderlng",
      headerName: "riderlng",
      flex: 1,
    },
    {
      field: "ridetype",
      headerName: "ridetype",
      flex: 1,
    },
    {
      field: "requesttime",
      headerName: "requesttime",
      flex: 1,
    },

    {
      field: "startlocation",
      headerName: "startlocation",
      flex: 1,
    },

    {
      field: "startlat",
      headerName: "startlat",
      filter: true,
      flex: 1,
    },
    {
      field: "Detail",
      headerName: "delete",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
          >
            {/* <Button onClick={()=>{handleopenDetail()}}> */}
            <Button>
              <AdminPanelSettingsOutlinedIcon />
            </Button>
          </Box>
        );
      },
    },
  ];

  //End GroupWaiting

  //Feedback
  const feedback = [
    {
      field: "feedbacktype",
      headerName: "feedbacktype",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "feedback",
      headerName: "feedback",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },

    {
      field: "response",
      headerName: "Response ",
      flex: 1,
    },
    {
      field: "handler",
      headerName: "handler ",
      flex: 1,
    },

    {
      field: "WRITE",
      headerName: "response",
      filter: true,
      flex: 1,
      renderCell: ({ row: { id, handler } }) => {
        return handler == null ? (
          <Typography>
            <TextField
              name="approvalstatus"
              required
              onChange={(e) =>
                setApproval({ ...status, status: e.target.value })
              }
              placeholder="waliif"
            ></TextField>
          </Typography>
        ) : null;
      },
    },
    {
      field: "Detail",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id, handler } }) => {
        return handler == null ? (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
          >
            <Button
              type="submit"
              onClick={() => {
                hadlefeedbackresponse(`${id}`);
              }}
            >
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                Response
              </Typography>
            </Button>
          </Box>
        ) : null;
      },
    },
  ];

  //end feedback

  return (
    <Box m="20px">
      <Box
        sx={{ bgcolor: "background.paper", width: "100%", height: "100%" }}
        p="10px"
      >
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            backgroundColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full"
            display="flex"
          >
            <Tab label="Order List" {...a11yProps(0)} />
            <Tab label="Waiting Order" {...a11yProps(1)} />
            <Tab label="Group Order" {...a11yProps(2)} />
            <Tab label="Feedback" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box m="20px">
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              {/* <Box>
      <Button   variant="contained" color="secondary" sx={{m:1}}> 
        Number Driver Online:</Button>
      <Button   variant="contained" color="secondary" sx={{m:1}}> 
         Driver OFF Line 
      </Button>
         <Button   variant="contained" color="secondary" sx={{m:1}}> 
         Waiting Order
      </Button>
      <Button   variant="contained" color="secondary" sx={{m:1}}> 
         Active Drivers</Button>
      <Button   variant="contained" color="secondary" sx={{m:1}}> 
         Create Order</Button>
      <Button   variant="contained" color="secondary" sx={{m:1}}> 
         Value</Button>
      <Button   variant="contained" color="secondary" sx={{m:1}}> 
         Trip Rate</Button>
        </Box> */}
              {/* <Button onClick={{handleopenDetail}}>waliffffffffffffffffffif</Button> */}

              <Modal
                open={openDetailTransaction}
                onClose={closeOpenTransaction}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <>
                  <style media="print">{`
        ${Object.entries(stylePrint)
          .map(
            ([key, value]) =>
              `${key} {${Object.entries(value)
                .map(([k, v]) => `${k}: ${v};`)
                .join("")}}`
          )
          .join("")}
      `}</style>
                  <Box
                    sx={style2}
                    //gridColumn="span 6"
                    backgroundColor={colors.grey[400]}
                    // display="flex"
                    display="flex"
                    gridRow="span 3"
                    // marginLeft={20}
                    marginRight={50}
                    id="printable-content"
                  >
                    <Box justifyContent="end">
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Detail Information on single Order
                        <table border="2">
                          <tr background="Red">
                            <td>Attribute</td>
                            <td>Value</td>
                            <td>Attribute</td>
                            <td>Value</td>
                            <td>Attribute</td>
                            <td>Value</td>
                            <td>Attribute</td>
                            <td>Value</td>
                          </tr>
                          <tr>
                            <td>Order S.N</td>
                            <td>{idno}</td>
                            <td>Driverid</td>
                            <td>{driverid}</td>

                            <td>Drivername</td>
                            <td>{drivername}</td>
                            <td>Driverphone</td>
                            <td>{driverphone}</td>
                          </tr>
                          <tr>
                            <td>Taxi Platenumber</td>
                            <td>{platenumber}</td>
                            <td>Drive Location</td>
                            <td>{driverlocation}</td>

                            <td>Driver Latitude</td>
                            <td>{driverlat}</td>
                            <td>Driver Longitude</td>
                            <td>{driverlng}</td>
                          </tr>
                          <tr>
                            <td>Passenger Id</td>
                            <td>{riderid}</td>
                            <td>Passenger Name</td>
                            <td>{ridername}</td>

                            <td>Passenger Phone</td>
                            <td>{riderphone}</td>
                            <td>end Location</td>
                            <td>{endlocation}</td>
                          </tr>
                          <tr>
                            <td>Rider Latitude</td>
                            <td>{riderlat}</td>
                            <td>Rider Longitude</td>
                            <td>{riderlng}</td>

                            <td>Ride Type</td>
                            <td>{ridetype}</td>
                            <td>Request Time</td>
                            <td>{requesttime}</td>
                          </tr>

                          <tr>
                            <td>Start location</td>
                            <td>{startlocation}</td>
                            <td>Route</td>
                            <td>{route}</td>

                            <td>Start Latitude</td>
                            <td>{startlat}</td>
                            <td>Start Longitude</td>
                            <td>{startlng}</td>
                          </tr>

                          <tr>
                            <td>End Latitude</td>
                            <td>{endlat}</td>
                            <td>End Longitude</td>
                            <td>{endlng}</td>

                            <td>Distance KM</td>
                            <td>{distance}</td>
                            <td>Expected Time</td>
                            <td>{duration}</td>
                          </tr>

                          <tr>
                            <td>Fare</td>
                            <td>{fare}</td>
                            <td>Start Date</td>
                            <td>{startdate}</td>

                            <td>End Date</td>
                            <td>{enddate}</td>
                            <td>Trip status</td>
                            <td>{statusO}</td>
                          </tr>

                          <tr>
                            <td>Reason</td>
                            <td>{reason}</td>
                            <td>Created At</td>
                            <td>{createdAt}</td>

                            <td>Updated At</td>
                            <td>{updatedAt}</td>
                          </tr>
                        </table>
                        <Box>
                          <Button
                            variant="secondary"
                            color="error"
                            onClick={closeOpenTransaction}
                            position="right"
                          >
                            Close
                          </Button>
                          <Button
                            variant="secondary"
                            color="error"
                            onClick={() => window.print()}
                          >
                            Print
                          </Button>
                          {statusO == "active" ? (
                            <Button
                              variant="secondary"
                              color="error"
                              onClick={() => handleDiscard(idno)}
                              position="right"
                            >
                              discard
                            </Button>
                          ) : null}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </>
              </Modal>

              <DataGrid
                style={{ height: "90%", width: "100%" }}
                rows={ordelist}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                rowHeight={20}
              />
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Box m="20px">
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                style={{ height: "100%", width: "100%" }}
                rows={gwaiting}
                columns={groupWaitingColumn}
                components={{ Toolbar: GridToolbar }}
                rowHeight={20}
              />
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <Box m="20px">
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                rows={waiting}
                columns={waitingcolumns}
                components={{ Toolbar: GridToolbar }}
                rowHeight={20}
              />
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Box m="20px">
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                rows={feedbacks}
                columns={feedback}
                components={{ Toolbar: GridToolbar }}
                rowHeight={20}
              />
            </Box>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
}

export default Orders;
