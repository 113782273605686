import * as React from 'react';
import { tokens } from "../../theme";
import { useTheme } from '@mui/material/styles';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EyeIcon from "@mui/icons-material/OpenInFull";

// import Header from "../../components/Header";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { useNavigate,useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import Modal from "@mui/material/Modal"; 
import { useFormik} from 'formik';
import { Snackbar,Alert} from '@mui/material';
import { AuthContext } from "../../context/AuthContext";
import Dialog from '@mui/material/Dialog';
import DialogActions, { dialogActionsClasses } from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle  from'@mui/material/DialogTitle';



const style2 = { 
	position: "absolute", 
	top: "40%", 
	left: "50%",
  height:"600", 
  transform: "translate(-50%, -50%)", 
	width: 700, 
  borderColor: "#d50000", 
	p: 1, 
};
const style = { 
	position: "absolute", 
	top: "50%", 
	left: "60%",
  transform: "translate(-50%, -50%)", 
	width: 900, 
  height: 600, 

  borderColor: "#d50000", 
	p: 4, 
}; 

const styleFile = { 
	position: "absolute", 
	top: "50%", 
	left: "60%",
  transform: "translate(-50%, -50%)", 
	width: 500, 
  height: 250, 

  borderColor: "#d50000", 
	p: 4, 
}; 



const VehicleDoc = () => {
  const { currentUser, url,logout } = useContext(AuthContext);
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
const [driver, setDriver] = useState([]);
const theme = useTheme();
const colors = tokens(theme.palette.mode);
// const url="https://walifride.com";
const [showAlert, setShowAlert] = useState(null);
const [captchaText, setCaptchaText] = useState(''); 
const [userInput, setUserInput] = useState(''); 
// const canvasRef = useRef(null); 
const navigate = useNavigate()
let { id } = useParams();


const [open, setOpen] = React.useState(false); 
const handleOpen = () => setOpen(true);
const handlecClose = () => setOpen(false);

const [opendel, setOpendel] = React.useState(false); 
const handleOpendel = () => setOpendel(true);
const handlecClosedel = () => setOpendel(false);

const [openZoom, setOpenZoom] = React.useState(false); 
const handleOpenZoom = () => setOpenZoom(true);
const handlecCloseZoom = () => setOpenZoom(false);


const [openphoto, setoPenPhoto] = React.useState(false);
const handleOpenPhoto = () => setoPenPhoto(true);
const handleClosePhoto = () => setoPenPhoto(false);


const [open2, setOpen2] = React.useState(false);
const handleOpen2 = () => setOpen2(true);
const handlecClose2 = () => setOpen2(false);

  const handleClickOpen = () => {
    const confirm = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
    axios.post(url+`/api/vehiclesdocuments/confirm/${id}`, {username:currentUser.username},config)
  .then((res)=>{
    setOpen2(false)
  //  alert(res.data)
   setNotification({
    show: true,
    type: 'success',
    message: res.data 
  
});
setIsRedirecting(true);
const timer = setTimeout(() => {
   }, 2000);

return () => clearTimeout(timer);

  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
};

confirm();  // setOpen2(true);
  };

  const handleClose = () => {
    setOpen2(false);
  };

// const handleDownloadpdf=(bb,title)=>{
  // fetch (url).then(response=>response.blob()).then(blob=>{
  //   const blobURL=window.URL.createObjectURL(new Blob([blob]));
  //   const aTag=document.createElement('a');
  // const fileName=url.split("/").pop();
  // aTag.href=blobURL;
  // aTag.setAttribute("download",fileName);
  // document.body.appendChild(aTag);
  // aTag.click();
  // aTag.remove();

  // });
  // const handleDownloadpdf = (bb,title) => {
    const handleDownloadpdf = (bb,title, path) => {
      const parts = path.split('.');
      const ext = parts[parts.length - 1];
      const config = {
        headers: {
          'Authorization': `Bearer ${currentUser.accessToken}`
        },
        responseType: 'blob' // Ensure responseType is correctly set here
      };
    axios.post(`${url}/api/vehiclesdocuments/download/${bb}`, { username: currentUser.username }, config)
      .then((res) => {
        // FileDownload(res.data, "download.jpg"); // Uncomment this line if using FileDownload library
        // Alternatively, use vanilla JavaScript to handle the download
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', title+'_'+id+'.'+ext);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log('No valid token or session expired');
          logout(); // Assuming logout function exists to handle token expiration
        } else {
          console.error('Error downloading PDF:', error);
        }
      });
  };

// Approval
const [status, setApproval] = useState({
  
  username:currentUser.username,
  status:"",
})
function handleapproval(id){
  // alert(13131)
//  handleCloseregdetail(true)
const approvedocument = async () => {
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };
if(docStatus=="Approved"){
  window.location.reload();
   setNotification({
    show: true,
    type: 'info',
    message: "The file is already approved!"
});
setIsRedirecting(true);
     const timer = setTimeout(() => {
     }, 2000);
     return () => clearTimeout(timer);
}else{
axios.put(url+`/api/vehiclesdocuments/doc/${id}`, status,config)
  .then((res)=>{
    window.location.reload();
   setNotification({
    show: true,
    type: 'info',
    message: res.data
});
setIsRedirecting(true);
     const timer = setTimeout(() => {
     }, 3000);
   
     return () => clearTimeout(timer);
  })
}
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }

}
};

approvedocument();
}

//End approval

// Remove File
const [doctoremoved, setdoctoremoved] = useState(0)
const [imageSrc, setImageSrc] = useState('');

const [extention2, setExtention] = useState('');
const [docIDD, setDoccIDD] = useState('');
const [doctitle, setdocTitle]=useState('');
const [docStatus, setdocStatus]=useState('');
const [docpath, setDocPath]=useState('');
const handleZoom = async (pp,path,title, Status) => {      
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response =  await axios.post(url + `/api/vehiclesdocuments/document/display/${pp}`, { username: currentUser.username }, config);
        // setVehic(response.data.vehicle);
        setImageSrc(response.data.image);
        setExtention(response.data.extentions)
        setDoccIDD(pp)
        setdocTitle(title)
        setDocPath(path)
        setdocStatus(Status)
        setoPenPhoto(true)

      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };


// const [doctoremoved2, setdoctoremoved2] = useState(0)
const formik = useFormik({
  initialValues: {
  docId: doctoremoved,
  username:currentUser.username
}, onSubmit: (values) => {
  // console.log(values);
  //alert(values.platenumber+"wewwewe");
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  //  alert(values[value]); 
  }
 axios.post(url+`/api/vehiclesdocuments/docdelete/sds`, values).then((res) => {
  window.location.reload();
  setOpendel(false)
// alert(res.data)
    });
  },
  });

  
 
function removefile(id){
// setOpendel(true) 
const remove = async () => {
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };
axios.post(url+`/api/vehiclesdocuments/del/${id}`, {username:currentUser.username}, config)
  .then((res)=>{
    window.location.reload();
  //  alert(res.data)
   setNotification({
    show: true,
    type: 'info',
    message: res.data
});
setIsRedirecting(true);
     const timer = setTimeout(() => {
     }, 3000);
        return () => clearTimeout(timer);
  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
};

remove();
}



const [vehicledoc, setvehicledoc] = useState([]);

useEffect(() => {
  const fetchsetvehicledoc = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + `/api/vehicles/doc/${id}`, { username: currentUser.username }, config);
      setvehicledoc(response.data);
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};

fetchsetvehicledoc();
}, [currentUser.username, currentUser.accessToken]);

const formics = useFormik({
  initialValues: {
    path: '',
    title: '',
    remark: '',
    vehicle: id,
    status:"",
    username:currentUser.username
  },
  onSubmit: (values) => {
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    const uploadfile = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
    
    axios.post(url+'/api/vehiclesdocuments', formData,config).then((res) => {
      // setdoc(doc.concat(res.data));
      window.location.reload();
      setOpen(false)
      setNotification({
        show: true,
        type: 'info',
        message: res.data
    });
    setIsRedirecting(true);
         const timer = setTimeout(() => {
         }, 2000);
         return () => clearTimeout(timer);
    }) }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};

uploadfile();
  },
});


const columndoc = [
  {
    field: "id",
    headerName: "id",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "title",
    headerName: "Document Title",
    flex: 3,
    cellClassName: "name-column--cell",
  },
  {
    field: "createdby",
    headerName: "Created by",
    // type: "number",
    headerAlign: "left",
    align: "left",
    flex: 2,
  },
  {
    field: "approvedby",
    headerName: "Approved by",
    // type: "number",
    headerAlign: "left",
    align: "left",
    flex: 2,
  },
  {
    field: "Status",
    headerName: "status",
    flex: 1,
  },
  {
    field: "remark",
    headerName: "Remark",
    flex: 1,
    flex: 2,
  },
  {
    
    headerName: "Detail",
    flex: 1,
    renderCell: ({ row: { id,title ,path,Status} }) => {
      return (
        Status!="Approved"?
        <Box

          backgroundColor={
              colors.redAccent[600]
          }
          borderRadius="4px"
        >
          
          
             <Button color ="error"onClick={ () => {handleZoom(id, path,title, Status)}}>
            Detail
             </Button>
        </Box>
        :
        <Box
   
        backgroundColor={
            colors.blueAccent[600]
        }
        borderRadius="4px"
      >
        
        
           <Button color ="warning"onClick={ () => {handleZoom(id, path,title)}}>
          Detail
           </Button>
      </Box>
      );
    },
  },
  // {
  //   field: "updatedAt",
  //   headerName: "Action",
  //   flex: 1,
  //   renderCell: ({ row: {id, Status} }) => {
  //     return (
  //       Status!="Approved"?
  //       <Typography>
  //         <TextField select name="approvalstatus" 
  //         required
  //           onChange={(e)=> setApproval({...status, status: e.target.value})}    
  //           placeholder='waliif'>
  //           <MenuItem value="Approved">
  //           Approved
  //         </MenuItem>
  //         <MenuItem value="Reject">
  //           Reject
  //         </MenuItem>
  //       </TextField>
  //      </Typography>
  //      :
  //      <Box

  //      backgroundColor={
  //          colors.greenAccent[100]
  //      }
  //      borderRadius="4px"
  //    >
  //      <Button type="submit" onClick={ () => {removefile(`${id}`)} }>
  //       Remove
  //      </Button>
       
  //    </Box>
  //     );
  //   }
  // },
  // {
  //   field: "accessLevel",
  //   headerName: "Detail",
  //   flex: 1,
  //   renderCell: ({ row: { id,Status } }) => {
  //     return (
  //       Status!="Approved"?
  //       <Box

  //         backgroundColor={
  //             colors.greenAccent[100]
  //         }
  //         borderRadius="4px"
  //       >
  //         <Button type="submit" onClick={ () => {handleapproval(`${id}`)} }>
  //          Apply
  //         </Button>
          
  //       </Box>
  //       :null
  //     );
  //   },
  // },
   ];

  return (
    
<Box m="20px"
    style2={{height: '85%'}}
    backgroundColor={colors.grey[400]}
  >

  <Box
          //gridColumn="span 6"
          backgroundColor={colors.grey[400]}
  
          display="flex"
          gridRow="span 3" 
          // marginLeft={20}
          marginRight={50}
         
          sx={style2}
         width={500}
         // backgroundColor="#2979ff"
          >

  </Box>
<Typography sx={{ m: 1 }}>
<Button type="submit" color="error" variant='contained'sx={{ m: 1 }}  onClick={ () => {navigate(`/vehicle/${id}`)} }>Profile</Button>
<Button type="submit" color="secondary" sx={{ m: 1 }}variant='contained' onClick={handleOpen}>ADD File</Button>
   <Button type="submit" color="info" variant='contained'sx={{ m: 1 }}onClick={handleOpen2}>Confirm Vehicle</Button></Typography>
  {/* <React.Fragment> */}
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        open={open2}
        onClose={handlecClose2}
       
      >
        <DialogTitle backgroundColor={colors.grey[400]}>Confirmation Dialog</DialogTitle>
        <DialogContent backgroundColor={colors.grey[400]}>
          <DialogContentText>
          I confirm that the vehicle documents listed below satisfy the requirements
           to provide service according to Waliif Transport Sc          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handlecClose2} color='error'variant="contained">Cancel</Button>
          <Button type="submit" onClick={handleClickOpen}color='secondary' variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
      {/* start Dialog to removed file */}
      <Dialog
        open={opendel}
        onClose={handlecClosedel}
      >
        <DialogTitle backgroundColor={colors.grey[400]}>Confirmation Dialog</DialogTitle>
        <form className='formContainer' onSubmit={formik.handleSubmit} encType='multipart/form-data'>
       
        <DialogContent backgroundColor={colors.grey[400]}>
      <DialogContentText>
      
      <Typography>        
          To confirm Input document ID 
      </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  onClick={handlecClosedel} color='error'variant="contained" sx={{m:1}}>Cancel</Button>
          <Button  color='secondary' sx={{m:1}}variant="contained">  Confirm</Button>
         
        </DialogActions>
        </form>
      </Dialog>
  
      {/* End dialog to remove file */}
  

      {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )} 



<Modal
      	open={open} 
				onClose={handlecClose} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>    
        <Box
      backgroundColor={colors.grey[400]}
      display="flex"
      gridRow="span 3"
      marginRight={50}
      width={20}
      sx={styleFile} 
    >
      <form className='formContainer' onSubmit={formics.handleSubmit} encType='multipart/form-data'>
       <ListItem>
         <ListItemText  id="dis2"primary="File" />
           <input type="file"  name="path"
           required
              onChange={(e) => {
               let reader = new FileReader();
               reader.onload = () => {
                 if (reader.readyState === 2) {
                     formics.setFieldValue('path', e.target.files[0])
                    }
                }
               reader.readAsDataURL(e.target.files[0]);
               }}
            />
         <ListItemText primary="Title"  id="dis4" sx={{m:1}}/>
         <FormControl  size="small"  id="dis5">
           {/* sx={{ m: 1, minWidth: 120 }} */}
              <Select name="title"
                onChange={formics.handleChange}
                value={formics.values.title}
                size='small'
                variant="filled"
                required
                id="dis6"
                sx={{m:1,
                  '& .MuiInputBase-root': {
                    height: 40, 
                  },
                }}
                InputProps={{
                  style: {
                    padding: '0 14px', 
                  },
                }} >
                  <MenuItem value="vehicleimage">vehicleimage</MenuItem>
                  <MenuItem value="libre">libre</MenuItem>   
                  <MenuItem value="businesslicense"> businesslicense</MenuItem>       
                  <MenuItem value="tincertificate">tincertificate</MenuItem>        
                  <MenuItem value="thirdparty">thirdparty</MenuItem>     
                  <MenuItem value="authorization">authorization</MenuItem>   
                  
              </Select>
         </ FormControl>
        </ListItem>
        <ListItem>
         <ListItemText primary="Remarks" />
         <TextField 
          sx={{m:1,
            '& .MuiInputBase-root': {
              height: 40, 
            },
          }}
          InputProps={{
            style: {
              padding: '0 14px', 
            },
          }} 
          name="remark" 
            onChange={formics.handleChange}
            value={formics.values.remark}/>
            {/* <ListItemText primary="Date" />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
           <DatePicker />
          </LocalizationProvider> */}
        </ListItem>
        <Button type="submit" color="secondary" variant="contained" sx={{m:1}}>  Add File</Button>
        <Button color="error" onClick={handlecClose}variant="contained" sx={{m:1}}> Close </Button> &nbsp;&nbsp;&nbsp;
      </form>
        </Box>
        
      </Modal>


      {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )} 
          <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}>
                 {/* <Button type="submit" onClick={ () => {navigate(`/driverdocument/${id}`)} }>document</Button> */}

                
                 
       <DataGrid rows={vehicledoc} columns={columndoc} components={{ Toolbar: GridToolbar }} style={{ height: '100%', width: '100%' }}  rowHeight={20}
>
</DataGrid>

<Modal
  open={openphoto}
  onClose={handleClosePhoto}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box
    backgroundColor={colors.grey[400]}
    display="flex"
    flexDirection="column" // Add this line to stack elements vertically
    gridRow="span 6"
    marginRight={50}
    width={100}
    sx={style}
  >
    {extention2 === '.pdf' ? (
      <Typography>The file is pdf please download and check before approve the vehicle information</Typography>
    ) : (
      imageSrc && <img src={imageSrc} width="800px" height="500px" alt="Vehicle Image" />
    )}

    <Box
      gridRow="span 4"
      display="flex"
      flexDirection="column" // Add this line to stack elements vertically
    >
      <Typography>
        <TextField
        label="Manage Status"
          select
          name="approvalstatus"
          required
          onChange={(e) => setApproval({ ...status, status: e.target.value })}
          placeholder='waliif'
          sx={{m:1,
            '& .MuiInputBase-root': {
              height: 40, 
            },
          }}
          InputProps={{
            style: {
              padding: '0 14px', 
            },
          }} 
        >
          <MenuItem value="Approved">Approved</MenuItem>
          <MenuItem value="Reject">Reject</MenuItem>
        </TextField>
        <Button type="submit" onClick={ () => {handleapproval(docIDD) }} sx={{m:1,
            '& .MuiInputBase-root': {
              height: 40, 
            },
          }}
          InputProps={{
            style: {
              padding: '0 14px', 
            },
          }} >
           Apply
          </Button>
        <Button  variant=""color="secondary"onClick={() => { handleDownloadpdf(docIDD, doctitle, docpath) }}>
        <DownloadOutlinedIcon />
      </Button>  
      <Button type="submit" onClick={ () => {removefile(docIDD)} }>
        Remove
       </Button>
      <Button type="" color="error" onClick={handleClosePhoto}variant="contained">Close</Button>

      </Typography>
    </Box>

  </Box>
</Modal>



</Box>

</Box>
   
);

};

export default VehicleDoc;
