import { GoogleMap, Marker, DirectionsRenderer, Autocomplete, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import React, { useState, useRef } from 'react';
import { Stack, Box, Input, Button, ButtonGroup, IconButton, Typography, Skeleton } from '@mui/material';
import NearMeIcon from '@mui/icons-material/NearMe';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
const center = {
  lat: 8.98755,
  lng: 38.7903
  
};

function Map() {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDEHFm4TKPbe5G5-8QCxbx9SNGLxf51iEc',
        libraries: ['places'],
    })
    const [distance, setDistance] = useState(null);
    const [map, setMap] = useState(/** @type google.maps.Map */ (null))
    const [directionsResponse, setDirectionsResponse] = useState(null)
    // const [distance, setDistance] = useState('')
    const [duration, setDuration] = useState('')

    /** @type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef()
    /** @type React.MutableRefObject<HTMLInputElement> */
    const destiantionRef = useRef()
    const inputRef = useRef();
    if (!isLoaded) {
        return <Skeleton variant="rectangular" width={210} height={118} />
    }
    const handlePlaceChanged = () => { 
        const [ place ] = inputRef.current.getPlaces();
        if(place) { 
            console.log(place.formatted_address)
            console.log(place.geometry.location.lat())
            console.log(place.geometry.location.lng())
        } 
    }

    async function calculateRoute() {
        if (originRef.current.value === '' || destiantionRef.current.value === '') {
        return
        }
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
        origin: originRef.current.value,
        destination: destiantionRef.current.value,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        })
        setDirectionsResponse(results)
        setDistance(results.routes[0].legs[0].distance.text)
        setDuration(results.routes[0].legs[0].duration.text)
    }
    function clearRoute() {
    //     setDirectionsResponse(null)
    //     setDistance('')
    //     setDuration('')
    //     originRef.current.value = ''
    //     destiantionRef.current.value = ''
    }


  const calculateDistance = async () => {
    const apiKey = 'AIzaSyDEHFm4TKPbe5G5-8QCxbx9SNGLxf51iEc';
    const origin = '8.98755,38.7903'; // San Francisco
    const destination = '8.08755,-37.7903'; // Los Angeles

    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${originRef}&destinations=${destiantionRef}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const result = response.data;
      const distanceInMeters = result.rows[0].elements[0].distance.value;
      setDistance(distanceInMeters);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

    return (
        <Box
            position='relative'
            flexDirection='row'
            alignItems='center'
            height='100%'
            width='100%'
        >
            <Box position='absolute' left={0} top={0} height='100%' width='100%'>
                <GoogleMap
                    center={center}
                    zoom={19}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                    }}
                    onLoad={map => setMap(map)}
                >
                    <Marker position={center} />
                    {directionsResponse && 
                        <DirectionsRenderer directions={directionsResponse} />
                    }
                              <Marker position={{ lat: 8.97778, lng: 38.7994 }} />
                </GoogleMap>
            </Box>
            <Box
                p={2}
                borderRadius={5}
                // m={4}
                boxShadow='inherit'
                display='inline-flex'
                bgcolor='gray'
                // color='blue'
                shadow='base'
                position='absolute'
                top={5}
                minWidth={296}
                ml={2}
                mr={2}
                zIndex={1}
            >
            <Stack spacing={2}>
             <Box sx={{display:'inline-flex'}}justifyContent='space-between'>
             <StandaloneSearchBox
             onLoad={ref => inputRef.current = ref}
             onPlacesChanged={handlePlaceChanged}
               >
            <input
                type="text"
                className="form-control"
                placeholder='Origin' ref={originRef}
               
            />
            
           </StandaloneSearchBox>
           <StandaloneSearchBox
            onLoad={ref => inputRef.current = ref}
            onPlacesChanged={handlePlaceChanged}
             >
            <input
                type="text"
                className="form-control"
                placeholder='Destination' ref={destiantionRef}
            />
            
            </StandaloneSearchBox>

                        <Box flexGrow={1} mx={2}>
                            <Autocomplete>
                                <Input type='text' placeholder='Destination' ref={destiantionRef} />
                            </Autocomplete>
                        </Box>
                        <ButtonGroup>
                            <Box flexGrow={1} mx={2} color='#4ca9ee'>
                                <Button type='submit' onClick={calculateRoute}>
                                    Go
                                </Button>
                            </Box>
                            <Box flexGrow={1} mx={2}>
                                <IconButton
                                    aria-label='center back'
                                    onClick={clearRoute}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </ButtonGroup>
          
                    </Box>
                    <Box sx={{display:'inline-flex'}} justifyContent='space-between'>
                        <Typography ml={2} mt={1} >Distance: {distance} </Typography>
                        <Typography mt={1}>Duration: {duration} </Typography>
                        <IconButton
                            aria-label='center back'
                            isRound
                            onClick={() => {
                                map.panTo(center)
                                map.setZoom(19)
                            }}
                        >
                            <Box mr={2}>
                            <NearMeIcon />
                            </Box>
                        </IconButton>
                    </Box>
                </Stack>
          
            </Box>
            
        </Box>
    )
}

export default Map