import { Box, FormControl, 
  Select,MenuItem,InputLabel,List,ListItem, ListItemText,Typography,  Alert, Snackbar,
   useTheme,Button,TextField} from "@mui/material";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Mony from'@mui/icons-material/SaveAsRounded';
import { AuthContext } from "../../context/AuthContext";
import React, { useEffect, useState, useContext} from "react";
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import { useFormik } from 'formik';

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx2={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const style2 = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 
  
};
const stylePrint = {
  body: {
    '*': {
      visibility: 'hidden',
    },
  },
  '#printable-content': {
    visibility: 'visible',
    position: 'absolute',
    left: 0,
    top: 0,
  },
};
const Employee = () => {
const theme = useTheme();

const[opendetail,setOpendetail]=React.useState(false);
const handleCloseregdetail = () => setOpendetail(false);
const handleOpendetail = () => setOpendetail(true);

const [open, setOpen] = React.useState(false); 
const handleOpen = () => setOpen(true);
const [opent, setOpent] = React.useState(false);
const handleOpent = () => setOpent(true);

const [opencreate, setOpencreate] = React.useState(false); 
const handleOpencreate = () => setOpencreate(true);
const handleacClose = () => setOpencreate(false);



const handleClosereg = () => setOpen(false);
const handleCloseregt = () => setOpent(false);

const [openDetailTransaction,setopenDetailTransaction]=React.useState(false)
const handleopenDetailTransaction=()=>setopenDetailTransaction(true)
const closeOpenTransaction =()=>setopenDetailTransaction(false)




const colors = tokens(theme.palette.mode);
const navigate = useNavigate()

const isNonMobile = useMediaQuery("(min-width:600px)");
const { currentUser,url,logout } = useContext(AuthContext);
// const [userreg, setValues] = useState([]);
const [value, setValue] = React.useState(0);
const [notification, setNotification] = useState(null);
const [isRedirecting, setIsRedirecting] = useState(false);

// Transaction
const [values, setValues] = useState({
  transactionmethod: "",
  username:currentUser.username,
  amaunt:"" 
})
function handleTransactions(e){
  e.preventDefault()
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };
  
  axios.post(url+'/api/emptransac/regstaff', values,config)
  .then((res)=>{
    setOpent(false);
      if(res.data=="ok"){
        // alert("Request Success")
        setNotification({
          show: true,
          type: 'success',
          message: "Request Success "
      });
     }else if(res.data=="nouser"){
  
       setNotification({
        show: true,
        type: 'info',
        message: "You are not allowed, to perform this task "
    });
     }
     else if(res.data=="noaccout"){
       setNotification({
        show: true,
        type: 'info',
        message: "No account associated with your username "
    });
     }else if(res.data=="exist"){
       setNotification({
        show: true,
        type: 'info',
        message: "You have already requested, Please, wait petiantly! "
    });
       
     } else if (res.data=="nofile"){
       setNotification({
        show: true,
        type: 'info',
        message: "No Reciept attached, Please try again! "
    });
     }
     else{
       setNotification({
        show: true,
        type: 'error',
        // message: "You have a truble, Please contact Admin!"
        message:res.data
    });
     }
     setIsRedirecting(true);

     const timer = setTimeout(() => {
         // navigate('/');
         window.location.reload();

        }, 2000);
   
     // Clean up the timer when the component is unmounted
     return () => clearTimeout(timer);
          // console.log(res);
  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }

};
}


// Approval

const [status, setApproval] = useState({
  
  username:currentUser.username,
  status:"",
})
function handleapproval(id){
  // alert(13131)
//  handleCloseregdetail(true)
try {
  const config = {
      headers: {
          'Authorization': `Bearer ${currentUser.accessToken}`
      }
  };

axios.put(url+`/api/emptransac/${id}`, status,config)
  .then((res)=>{
  //  alert(res.data)
  //  setOpencreate(true);
   setNotification({
    show: true,
    type: 'info',
    message: res.data
});
setIsRedirecting(true);

     const timer = setTimeout(() => {
         // navigate('/');
        //  setOpencreate(false)
     }, 3000);
   
     // Clean up the timer when the component is unmounted
     return () => clearTimeout(timer);
     
    // navigate('/agents');
  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }

};
}

//End Approval


// Start account creation
const [createacc, setCreateAcc] = useState({

  requstedUsername: "",
  accounttype:"",
  username:currentUser.username,
  
})
function handleaccountcreation(e){
  e.preventDefault()
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };
  axios.post(url+'/api/empacc/create', createacc,config)
  .then((res)=>{
      setOpencreate(false)
      if(res.data=="yes"){
        setNotification({
          show: true,
          // position:t
          type: 'success',
          message: "Congratulation Account has been successfully created"
      });
      }
      else if(res.data=="exist"){
       
        setNotification({
          show: true,
          type: 'info',
          message: "Account associated with the username already Exist"
      });
      }
      else if(res.data=="error"){
        setNotification({
          show: true,
          type: 'error',
          message: "Sorry, The operation is failed"
      });
     }
     else{
      setNotification({
        show: true,
        type: 'error',
        message: res.data
    });
     }

     setIsRedirecting(true);

     const timer = setTimeout(() => {
     window.location.reload();
     }, 3000);
     return () => clearTimeout(timer);
     })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }

};
}

//End account creation 
const [chargebal, setBal] = useState([]);
   const formics = useFormik({
    initialValues: {
      Reciept: "",
      amaunt: "",
      username:currentUser.username
    },
    onSubmit: (values) => {
      // console.log(values);
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
      axios.post(url+'/api/emptransac/reg', formData,config).then((res) => {
        setBal(chargebal.concat(res.data));
       // handleopenaddfile=setaddfile(false);
        if(res.data=="ok"){
          setNotification({
            show: true,
            type: 'success',
            message: "Request Success"
        });
        }else if(res.data=="nouser"){
          setNotification({
            show: true,
            type: 'error',
            message: "Access Denied"
        });
          }
        else if(res.data=="noaccout"){
          setNotification({
            show: true,
            type: 'error',
            message: "No account associated with your username"
        });
        }else if(res.data=="exist"){
        //  alert("")
          setNotification({
            show: true,
            type: 'info',
            message: "You have already requested, Please, wait petiantly!"
        });
          
        } else if (res.data=="nofile"){
        //  alert("")
          setNotification({
            show: true,
            type: 'info',
            message: "No Reciept attached, Please try again!"
        });
        }
        else{
          setNotification({
            show: true,
            type: 'info',
            message: "You have a truble, Please contact Admin"
        });
        
        }
        setIsRedirecting(true);

        const timer = setTimeout(() => {
            // navigate('/');
            setOpen(false)
            window.location.reload();

          }, 2000);
      
        return () => clearTimeout(timer);
       
      });
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
    
    };
    
    },
  });


  const [recieved, setRecieved] = useState({
  
    username:currentUser.username,
   // recievedstatus:"",
  })


  function handleRecieved(id){

    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
  axios.post(url+`/api/emptransac/recievedcash/${id}`, recieved,config)
  
    .then((res)=>{
      setNotification({
        show: true,
        type: 'info',
        message: res.data
    });
    })
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
  
  };
  
  }

 
  const [acc, setrequestcharge] = useState([]);
  

  useEffect(() => {
    const emptranfetch = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/empacc/all', { username: currentUser.username }, config);
        setrequestcharge(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };
  emptranfetch();
}, [currentUser.username, currentUser.accessToken]);

const [tx, setTX] = useState([]);



  useEffect(() => {
    const emptransaction = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + "/api/emptransac/all", { username: currentUser.username }, config);
        setTX(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };
  emptransaction();
}, [currentUser.username, currentUser.accessToken]);

  const [pendReq, setPendReq] = useState([]);
  useEffect(() => {
    const fetchsetPendReq = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + "/api/emptransac/pend", { username: currentUser.username }, config);
        setPendReq(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };
  fetchsetPendReq();
}, [currentUser.username, currentUser.accessToken]);


const [idno,setId]=useState(0)
const [accC,setACC]=useState(0)
const [sta,setsta]=useState(0)
const [actype,setactype]=useState(0)
const [bal,setBalance]=useState(0)
const [transfer,setTran]=useState(0)
const [bonus,setbonus]=useState(0)
const [createdAt,setcreatedAt]=useState(0)
const[updatedAt,setupdatedAt]=useState(0)

function handleopenDetailTransactionDisp(id,ac,accountType,balance,transfered,bonus,createdAt,st,updatedAt){
  let dateObject = new Date(createdAt);
let formattedDate = dateObject.getFullYear() + '/' +
                   (dateObject.getMonth() + 1).toString().padStart(2, '0') + '/' +
                   dateObject.getDate().toString().padStart(2, '0');

let dateObject2 = new Date(updatedAt);
let formattedDate2 = dateObject2.getFullYear() + '/' +
                  (dateObject2.getMonth() + 1).toString().padStart(2, '0') + '/' +
                  dateObject2.getDate().toString().padStart(2, '0');

  setId(id)
  setACC(ac)
  setsta(st)
  setBalance(balance)
  setactype(accountType)
  setTran(transfered)
  setbonus(bonus)
  setcreatedAt(formattedDate)
  setupdatedAt(formattedDate2)

  setopenDetailTransaction(true)
  // alert(id+"----"+ac+"---"+"----"+st)
}
  const columns = [
    // { field: "id", headerName: "ID" },
    {
      field: "id",
      headerName: "No",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "accountnumber",
      headerName: "Acccount Number ",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "accountType",
      headerName: "Account Type",
      // type: "number",
      headerAlign: "left",
      align: "left",
      flex: 2,

    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
    },
    
    {
      field: "transfered",
      headerName: "transfered", filter:true,
      flex: 1,
    },
    {
      field: "status",
      headerName: "status", filter:true,
      flex: 1,
    },
    
    {
      field: "createdAt",
      headerName: "createdAt",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "updatedAt",
      flex: 1,
    },
    {
      field: "collect",
      headerName: "Collect",
      flex: 1,
      renderCell: ({ row: { id ,transfered,accountnumber} }) => {
        return (
          transfered!=0 && accountnumber!=7071 && accountnumber!=7072&& accountnumber!=7073&& accountnumber!=7074?
          <Box
 
            backgroundColor={
                colors.blueAccent[600]
            }
            borderRadius="4px"
          >
            <Button type="submit" onClick={ () => {handleRecieved(`${id}`)} }
                    sx={{
                      fontSize: '8px',
                      padding: '6px 10px',
                    }}
            >
              collect

            </Button>
          </Box>
          :null
        );
      },
    },
    {
      field: "Edit",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id, accountnumber,status,accountType,balance,transfered,bonus,createdAt,updatedAt } }) => {
        return (
          accountnumber!=7071 && accountnumber!=7072 && accountnumber!=7073  && accountnumber!=7074?
          <Box
            backgroundColor={
                colors.blueAccent[600]
            }
          >
            <Button onClick={()=>{handleopenDetailTransactionDisp(`${id}`,`${accountnumber}`,`${accountType}`,
            `${balance}`,`${transfered}`,`${bonus}`,`${createdAt}`,`${status}`,`${updatedAt}`)}}
            sx={{
              fontSize: '8px',
              padding: '6px 10px',
            }}>
              <AdminPanelSettingsOutlinedIcon />

            </Button>
          </Box>
          :null
        );
      },
    },
     ];
     //start
     const column = [
      // { field: "id", headerName: "ID" },
      {
        field: "username",
        headerName: "ID",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "previoustotal",
        headerName: "PrivevousB ",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "transactionmethod",
        headerName: "Tx Method",
        // type: "number",
        headerAlign: "left",
        align: "left",
      },
      {
        field: "acctype",
        headerName: "acctype",
        flex: 1,
      },
      {
        field: "creditaccount",
        headerName: "creditaccount",
        flex: 1,
      },
      {
        field: "debitaccount",
        headerName: "debitaccount",
        flex: 1,
      },
      {
        field: "status",
        headerName: "status", filter:true,
        flex: 1,
      },
      {
        field: "amaunt",
        headerName: "amaunt",
        flex: 1,
      },
      {
        field: "newtotal",
        headerName: "total",
        flex: 1,
      },
      {
        field: "createdAt",
        headerName: "createdAt",
        flex: 1,
      },
      {
        field: "updatedAt",
        headerName: "updatedAt",
        flex: 1,
      },
      {
        field: "Edit",
        headerName: "Detail",
        flex: 1,
        renderCell: ({ row: {id } }) => {
          return (
            <Box
              width="60%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={
                  colors.greenAccent[600]
              }
              borderRadius="4px"
            >
              <Button type="submit">
              </Button>
            </Box>
          );
        },
      },
       ];
       const columnT = [
        // { field: "id", headerName: "ID" },
        {
          field: "username",
          headerName: "requestedby",
          flex: 1,
          cellClassName: "name-column--cell",
        },
        {
          field: "transactionmethod",
          headerName: "TxMethod",
          flex: 1,
          cellClassName: "name-column--cell",
        },
        {
          field: "approver",
          headerName: "approver",
          // type: "number",
          headerAlign: "left",
          align: "left",
        },
        
        {
          field: "creditaccount",
          headerName: "Credit Account",
          flex: 1,
        },
        {
          field: "amaunt",
          headerName: "amaunt", filter:true,
          flex: 1,
        },
        {
          field: "status",
          headerName: "status",
          flex: 1,
        },
        {
          field: "createdAt",
          headerName: "createdAt",
          flex: 1,
        },
        {
          field: "attachement",
          headerName: "Reciept photo",
          flex: 1,
          renderCell: ({ row: {attachement} }) => {
            return (
              <Typography>
                 <img src={url+attachement} width={295} height={365} alt=""/>

              </Typography>
            )}
        },
        {
          field: "updatedAt",
          headerName: "Action",
          flex: 1,
          renderCell: ({ row: {id} }) => {
            return (
              <Typography>
                <TextField select name="approvalstatus" 
                required
                  onChange={(e)=> setApproval({...status, status: e.target.value})}    
                  placeholder='waliif'>
                  <MenuItem value="Approved">
                  Approved
                </MenuItem>
                <MenuItem value="Reject">
                  Reject
                </MenuItem>
              </TextField>
             </Typography>
            );
          }
        },
        {
          field: "accessLevel",
          headerName: "Detail",
          flex: 1,
          renderCell: ({ row: { id } }) => {
            return (
              <Box

                backgroundColor={
                    colors.greenAccent[100]
                }
                borderRadius="4px"
              >
                <Button type="submit" onClick={ () => {handleapproval(`${id}`)} }>
                 Apply
                </Button>
                
              </Box>
            );
          },
        },
         ];
     
     //end
     const handleChange = (event, newValue) => {
      setValue(newValue);
       };
  return (
    <Box m="20px">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
		      backgroundColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		      display="flex"
        >
      <Tab label="Staff Account" {...a11yProps(0)} />
      <Tab label="Employee Transactions" {...a11yProps(1)} />
       <Tab label="Manage REQUEST" {...a11yProps(2)} /> 
		    </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <div >

          </div> 
          <Button type="submit" color="secondary" variant="contained"  onClick={handleOpencreate}>     
           Create Account
              </Button> &nbsp;&nbsp;


    <Modal 
      
      open={openDetailTransaction} 
      onClose={closeOpenTransaction} 
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      
    > 
       <>
      <style media="print">{`
        ${Object.entries(stylePrint).map(([key, value]) => `${key} {${Object.entries(value).map(([k, v]) => `${k}: ${v};`).join('')}}`).join('')}
      `}</style>
    <Box sx={style2  } 
      //gridColumn="span 6"
      backgroundColor={colors.grey[400]}
      // display="flex"
      display="flex"
      gridRow="span 3" 
      // marginLeft={20}
      marginRight={50}
       > 
          <Box  justifyContent="end">
          <Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
          <table border="1"     id="printable-content"> 
            <tr background-color= "#f2f2f2">
                <td>Attribute</td>
                <td>Value</td>
                <td>Attribute</td>
                <td>Value</td>
            </tr>
            <tr>
                <td>ID Number</td>
                <td>{idno}</td>
                <td>Account type</td>
                <td>{actype}</td>

            </tr>
            <tr>
                <td>Account Number</td>
                <td>{accC}</td>
                <td>Balance</td>
                <td>{bal}</td>

            </tr>
            <tr>
                <td>Transfered Amaunt</td>
                <td>{transfer}</td>
                <td>Bonus</td>
                <td>{bonus}</td>

            </tr>
            <tr>
                <td>Created Date</td>
                <td>{createdAt}</td>
                <td>Updated Date</td>
                <td>{updatedAt}</td>

            </tr>
         </table>
         <Box>
    
    <Button variant="secondary" color="error"onClick={closeOpenTransaction} position="right">
  Close
</Button>
<Button variant="secondary" color="error" onClick={() => window.print()}>
Print
</Button>
</Box>
        </Typography>
            
      </Box>

    </Box>
   
     
    </>
   </Modal>
			<Modal 
      
				open={opencreate} 
				onClose={handleacClose} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
        
			> 
      	<Box sx={style2  } 
                  //gridColumn="span 6"
                  backgroundColor={colors.grey[400]}
                 // display="flex"
                  gridRow="span 2" 
                  // marginLeft={20}
                  marginRight={50}
                  width={10}
        > 
      		<Typography id="modal-modal-description">
						 {/* sx={{ mt: 2 }} */}
					  <form onSubmit={handleaccountcreation}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            
              >  <List>
                <ListItem>          
              <TextField
                fullWidth
                variant="filled"
                type="text"
                size="small" 
                label="Requested Username"
                InputProps={{
                  style: {
                    height: 50,
                    width: 200,
                  },
                }}
                onChange={(e)=> setCreateAcc({...createacc, requstedUsername: e.target.value})}
                required
                sx={{m:1}}
                name="amaunt"
                ></TextField>
                </ListItem>
                
                <ListItem>
                <TextField
                sx={{m:2}}
                InputProps={{
                  style: {
                    height: 50,
                    width: 200,
                  },
                }}
                fullWidth
                variant="filled"
                type="text"
                select
                label="Account Type"
                style = {{width: 200}} 
                onChange={(e)=> setCreateAcc({...createacc, accounttype: e.target.value})}
                required
                // columns={2}
                name="amaunt"
                >
                  <MenuItem value="Staff"> Staff
                  </MenuItem>
                  <MenuItem value="Agent"> Agent
                  </MenuItem>
                </TextField>
                </ListItem>
                </List>
               </Box>
              <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
              <Mony/>Create
              </Button> &nbsp;&nbsp;
              <Button variant="secondary" color="error"onClick={handleacClose} position="right">
            Close
          </Button>
            </Box>
             
            </form>
            
					</Typography> 
          
				</Box> 
        
			</Modal>
          <Modal
            open={open} 
            onClose={handleClosereg} 
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
          <div >
          <Box
            m="20px"
            display="flex"
            marginTop={20}
            justifyContent="center"
            borderRadius={10}
            position="relative"
            transform="translate('-50%', '-50%')"
            alignItems="right"
            backgroundColor="#4ca9ee"//"#d3d3d3"
            height="70%"
            width="50%"
            marginLeft={70}
            marginBottom={10}
          >
          </Box>
          </div> 
          </Modal>
          {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}    
                <DataGrid style={{height: '90%', width: '100%'}} rows={acc} columns={columns}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>
              </Box>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
              <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}
              >
        
          {/* START TRANSACTION */}

        <Button type="submit" color="secondary" variant="contained"  onClick={handleOpent}>     
           Request Staff E-Money
              </Button> &nbsp;&nbsp;
              {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}      
        
			<Modal 
      
				open={opent} 
				onClose={handleCloseregt} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
        
			> 
				<Box sx={style2  } 
                  //gridColumn="span 6"
                  backgroundColor={colors.grey[400]}
                 // display="flex"agent
                  gridRow="span 2" 
                  // marginLeft={20}
                  marginRight={50}
                  width={10}
               
        > 
					<Typography id="modal-modal-title"
						variant="h6" component="h2"> 

				Charge e-money
					</Typography> 
					<Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
						
            <form onSubmit={handleTransactions}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >               <TextField
                fullWidth
                variant="filled"
                type="number"
                label="amaunt"
                style = {{width: 200}} 
                onChange={(e)=> setValues({...values, amaunt: e.target.value})}
                required
                columns={2}
                name="amaunt"
                ></TextField>
               </Box>
              <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
              Apply
              </Button> &nbsp;&nbsp;
              <Button variant="secondary" onClick={handleCloseregt} position="right">
            Close
          </Button>

            </Box>
               
            </form>
            
					</Typography> 
         
				</Box> 
        
			</Modal>
     
      <Modal 
      
				open={opendetail} 
				onClose={handleCloseregdetail} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
        
			> 
				<Box sx={style2  } 
                  //gridColumn="span 6"
                  backgroundColor={colors.grey[400]}
                 // display="flex"
                  gridRow="span 2" 
                  // marginLeft={20}
                  marginRight={50}
                  width={10}
               
        > 
					<Typography id="modal-modal-title"
						variant="h6" component="h2"> 

				Charge e-Money
					</Typography> 
					<Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
						
            <form onSubmit={handleTransactions}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >               <TextField
                fullWidth
                variant="filled"
                type="number"
                label="amaunt"
                style = {{width: 200}} 
                onChange={(e)=> setValues({...values, amaunt: e.target.value})}
                required
                columns={2}
                name="amaunt"
                ></TextField>
               </Box>
              <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
              <Mony/>Apply
              </Button> &nbsp;&nbsp;
              <Button variant="secondary" onClick={handleCloseregdetail} position="left">
            Close
          </Button>
            </Box>
               
            </form>
            
					</Typography> 
         
				</Box> 
        
			</Modal>

      {/* End Detail for approval */}

{/* END TRANSACT */}
<Modal 
				open={open} 
				onClose={handleClosereg} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"> 
        <Box
          //gridColumn="span 6"
          backgroundColor={colors.grey[400]}
          display="flex"
          gridRow="span 3" 
          // marginLeft={20}
          marginRight={50}
          width={20}
          sx={style2}
         // backgroundColor="#2979ff"
          >
       
          <Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
       <form className='formContainer' onSubmit={formics.handleSubmit} encType='multipart/form-data'>
       <ListItem>
         <ListItemText  primary="Upload Reciept" />
         <TextField
                fullWidth
                variant="filled"
                type="file"
                label="Photo"
               required
                name="Reciept"
                sx={{ gridColumn: "span 1" }}
                onChange={(e) => {
                  let reader = new FileReader();
                  reader.onload = () => {
                    if (reader.readyState === 2) {
                     formics.setFieldValue('Reciept', e.target.files[0])
                     }
                  }
                  reader.readAsDataURL(e.target.files[0]);
                }}
                ></TextField>   
        </ListItem>
        <ListItem>
         <ListItemText primary="Amaunt" />
         <TextField size="small" name="amaunt" type="number" 
            onChange={formics.handleChange}
            value={formics.values.amaunt}/>
            <TextField size="small" name="username" type="text" disabled 
            onChange={formics.handleChange}
            value={formics.values.username}/>
        </ListItem>
        <ListItem>
         <ListItemText primary="Remarks" />
         <TextField size="small" name="remark" 
            onChange={formics.handleChange}
            value={formics.values.remark}/>
        </ListItem>
        <Button type="submit" color="secondary" variant="contained"sx={{m:1}}>  Apply</Button>
        <Button color="error" onClick={handleClosereg}variant="contained"> Close </Button> &nbsp;&nbsp;&nbsp;
        {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}
      </form>
    </Typography>
   </Box>
 </Modal>

      <DataGrid style={{height: '90%', width: '100%'}} rows={tx} columns={column}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>
    </Box>
    </TabPanel>
    <TabPanel value={value} index={2} dir={theme.direction}>
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
       {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )} 


         <DataGrid style={{height: '100%', width: '100%'}} rows={pendReq} columns={columnT}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>
   
      </Box>
      </TabPanel>
  </Box>
);

};

export default Employee;
