import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Link, useNavigate} from 'react-router-dom';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import { Box,Divider, FormControl, Select,MenuItem,InputLabel,List,ListItem, ListItemText,Typography, useTheme,Button,TextField, Checkbox} from "@mui/material";
import  { useEffect, useState ,useContext } from "react";
import axios from 'axios';
import { useFormik } from 'formik';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Printpage} from '../../components/Hello';

import Modal from "@mui/material/Modal"; 
import { AuthContext } from '../../context/AuthContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{width: '100%'}}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
  
}
const style3 = { 
	position: "absolute", 
	top: "50%", 
	left: "60%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 800, 
  borderColor: "#d50000", 
	p: 4, 
}; 
 function Transactions() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false); 
  const handleOpen = () => setOpen(true);
  const handleClosereg = () => setOpen(false);

  const [data, setData] = useState([]); 
  const [fetch, setDsetFetch] = useState([]);
  const [user, setUser] = useState();
  // const url="http://128.140.76.56:3001";
  //  const url="http://localhost:3001";
  //  const url = "https://128.140.76.56:3443";
  // const url="https://walifride.com";
  //access transaction 
  const { currentUser, url,logout} = useContext(AuthContext);
  const [trans, setTransaction] = useState([]);
  useEffect(() => {
    const fetchtransaction = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
     const response = await axios.post(url + '/api/transactions/all/all', { username: currentUser.username }, config);
    //  const response = await axios.post(url + '/api/vehicles/all', { username: currentUser.username }, config);
  
     setTransaction(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
};

  fetchtransaction();
}, [currentUser.username, currentUser.accessToken]);
  //end to access transaction
 

  const navigate = useNavigate()

 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "previoustotal",
      headerName: "Previous Balance",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "transactionmethod",
      headerName: "T_Method",
      flex: 1,
    },
    {
      field: "transactionhandler",
      headerName: "Handler",
      flex: 2,
    },
    {
      field: "debitaccount",
      headerName: "Debited Account", filter:true,
      flex: 2,
    },
    {
      field: "creditaccount",
      headerName: "Credited Acc",
      flex: 2,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
    },
    {
      field: "commission",
      headerName: "commission",
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "vat",
      headerName: "vat",
     flex:1,
    
    },
    {
      field: "newtotal",
      headerName: "newtotal",
     flex:1,
    
    },
    {
      field: "createdAt",
      headerName: "createdAt",
     flex:2,
    
    },
    {
      field: "updatedAt",
      headerName: "updatedAt",
     flex:2,
    
    },    
    
  ];
  const driversCol = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "accountnumber",
      headerName: "Phone Number",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "balance",
      headerName: " Balance",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "DriverId",
      headerName: "Driver Id",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created date",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Updated Date", filter:true,
      flex: 1,
    },
    {
      field: "Detail",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: {id} }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
                colors.blueAccent[600]
            }
            borderRadius="4px"
          >
            
              <Button aria-describedby={open} onClick={handleOpen}      
                sx={{
                fontSize: '8px',
                padding: '6px 10px',
              }}> 
                Detail
              </Button>&nbsp;
            
            </Box>
        );
      },
    },
  ];


  return (
    <Box m="20px"
      style={{height: '85%'}}
    >
    <Box sx={{ bgcolor: 'background.paper', width: '100%', height: '100%' }} p="10px">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
    		  backgroundColor="dark"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		      display="flex"
        >
          <Tab label="Transaction List" {...a11yProps(0)} />
          {/* <Tab label="Manage Tn" {...a11yProps(1)} />
          <Tab label="Weekly" {...a11yProps(2)} />
	    	  <Tab label="Monthly" {...a11yProps(3)} />
    		  <Tab label="Per Users" {...a11yProps(4)} />
  		    <Tab label="Special" {...a11yProps(5)} />
		      <Tab label="Agents" {...a11yProps(6)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid style={{height: '90%', width: '100%'}} rows={trans} columns={columns}  components={{ Toolbar: GridToolbar }} rowHeight={20} />
          </Box>
        
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
      <Modal
          // backgroundColor="seagreen "
          // sx={style1} 
            open={open} 
            onClose={handleClosereg} 
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          > 

        <Box
          // //gridColumn="span 6"
          // backgroundColor={colors.grey[400]}
          // display="flex"
          // gridRow="span 3" 
          // // marginLeft={20}
          // marginRight={50}
          // width={20}
          // sx={style3}
         // backgroundColor="#2979ff"
                       //   gridRow="span 2" 
                  marginLeft={36}
                  marginTop={20}
                  marginRight={20}
                  backgroundColor={colors.grey[400]}
                  width={940}
                  
 
          >
        <Box
                 
                  //display="flex"
                  //gridRow="span 3" 
                  //marginLeft={50}
                  //marginRight={50}
                  
                  //sx={style3}
        
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            {/* <DataGrid style={{height: '90%', width: '100%'}} rows={accdriver} columns={driversCol}  components={{ Toolbar: GridToolbar }} /> */}
          <Button   onClick={handleClosereg} position="left" variant="contained"color="warning">
              Close
          </Button>
        </Box>

      </Box>
    </Modal>
    <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
      <TextField size='small' placeholder="username" label="username"></TextField>
        <DatePicker  size='small'/>
        <DatePicker  size='small'/>
        <Button type="submit" color="secondary" >Generate</Button>
        </DemoContainer>
      
    </LocalizationProvider>
            {/* <DataGrid style={{height: '88%', width: '100%'}} rows={accdriver} columns={driversCol}  components={{ Toolbar: GridToolbar }} /> */}
          </Box>

      </TabPanel>
      <TabPanel value={value} index={5} dir={theme.direction}>
        <label sx={{ m: 1, minWidth: 120 }} size="small">Report Category</label>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select name="transactionmethod"
            size="small"
            variant="filled"
            label="Report Category"
            required
            placeholder='Please select '
            sx={{ gridColumn: "span 2" }}
          >
            <MenuItem value="Revenue">Revenue</MenuItem>
            <MenuItem value="Staff">Staff Audit </MenuItem>
            <MenuItem value="Driver Deposit">Driver Deposit</MenuItem>
            <MenuItem value="Cash withdraw">Cash withdraw</MenuItem>
            <MenuItem value="Transaction">Transaction </MenuItem>
            <MenuItem value="Manage complain">Manage complain</MenuItem>
            <MenuItem value="">Company Expense </MenuItem>
          </Select>
        </ FormControl>
      </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
          Revenue
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Transactions;
